export function getCsrfToken() {
  const allCookies = document.cookie
  if (!allCookies) return null

  const cookieName = "csrftoken"
  const cookies = allCookies.split(";").map((it) => it.trim())
  for (const cookie of cookies) {
    if (cookie.startsWith(`${cookieName}=`)) {
      return decodeURIComponent(cookie.slice(cookieName.length + 1))
    }
  }
  return null
}
