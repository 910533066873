import type { Transaction } from "prosemirror-state"
import type { ReplaceStep } from "prosemirror-transform"

import { citationDropped } from "./citationDropped"
import { referenceParsed } from "./referenceParsed"
import { transactionSendTriggered } from "./transactionSendTriggered"

import { citationDropKey } from "@/plugins/citationDrop/citationDropPlugin"
import {
  type ReferenceParsingMeta,
  referenceParsingPluginKey,
} from "@/plugins/referenceParsing/referenceParsingPlugin"
import type { UUID } from "@/store/UUID"
import { getCitationsState } from "@/store/selectors/citationsSelectors"
import { revisionsSlice } from "@/store/slices/revisions"
import { createAppThunk } from "@/store/store"
import { sendErrorToSentry } from "@/utils/sentry"

export const transactionDispatched = createAppThunk(
  "documents/transactionDispatchedThunk",
  async (
    payload:
      | {
          documentType: "chapter" | "flashcard" | "note"
          documentId: UUID
          revisionId: UUID
          transaction: Transaction
          fromAtom?: false
        }
      | {
          documentType: "chapter" | "flashcard" | "note"
          documentId: UUID
          revisionId: UUID
          transaction: Transaction
          fromAtom: true
          atomPos: number
        },
    { dispatch, getState }
  ) => {
    const referenceParsingMeta = payload.transaction.getMeta(
      referenceParsingPluginKey
    ) as ReferenceParsingMeta | undefined

    if (referenceParsingMeta?.type === "parsed") {
      dispatch(
        referenceParsed({
          ...payload,
          parsed: referenceParsingMeta.payload,
        })
      )
    }

    const citationDropMeta = payload.transaction.getMeta(citationDropKey) as
      | { type: "drop" | "dragover" }
      | undefined

    if (citationDropMeta?.type === "drop") {
      const citationsState = getCitationsState(getState())
      const sourceDocumentId = citationsState?.documentId
      const highlightId = await dispatch(citationDropped()).unwrap()

      if (highlightId && sourceDocumentId) {
        const dropStep = payload.transaction.steps[0] as ReplaceStep
        payload.transaction.setNodeAttribute(
          dropStep.from,
          "annotation_id",
          highlightId
        )
        payload.transaction.setNodeAttribute(
          dropStep.from,
          "source_document_id",
          sourceDocumentId
        )
      }
    }

    try {
      dispatch(revisionsSlice.actions.transactionDispatched(payload))
    } catch (err) {
      sendErrorToSentry(`Could not process dispatched transaction`, err)
    }

    if (!payload.transaction.docChanged) return

    dispatch(
      transactionSendTriggered({
        documentType: payload.documentType,
        documentId: payload.documentId,
        revisionId: payload.revisionId,
      })
    )
  }
)
