import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"

export function stepMarkerSchema(): NodeSchema<"step_marker"> {
  return {
    step_marker: {
      attrs: {
        guid: CommonBlockAttrs.spec.guid,
      },
      selectable: true,
      draggable: true,
      group: "block",
      parseDOM: [
        {
          tag: 'div[data-type="step_marker"]',
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return [
          "div",
          {
            ...CommonBlockAttrs.serialize(node),
            "data-type": "step_marker",
          },
        ]
      },
    },
  }
}
