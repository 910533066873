import { type Node } from "prosemirror-model"

import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"

/**
 * @dependencies paragraph | bullet_list | ordered_list | blockquote
 */

export function tableSchema(): NodeSchema<
  "table" | "table_row" | "table_header" | "table_cell"
> {
  return {
    table: {
      attrs: CommonBlockAttrs.spec,
      content: "table_row+",
      tableRole: "table",
      isolating: true,
      group: "block",
      parseDOM: [{ tag: "table", getAttrs: CommonBlockAttrs.parse }],
      toDOM(node) {
        return ["table", CommonBlockAttrs.serialize(node), ["tbody", 0]]
      },
    },

    table_row: {
      content: "(table_cell | table_header)*",
      tableRole: "row",
      parseDOM: [{ tag: "tr" }],
      toDOM() {
        return ["tr", 0]
      },
    },

    table_cell: {
      content: "(paragraph | bullet_list | ordered_list | blockquote)+",
      attrs: cellAttrs,
      tableRole: "cell",
      isolating: true,
      parseDOM: [
        {
          tag: "td",
          getAttrs: (dom) => getCellAttrs(dom as HTMLElement, extraAttrs),
        },
      ],
      toDOM(node) {
        return ["td", setCellAttrs(node, extraAttrs), 0]
      },
    },

    table_header: {
      content: "(paragraph | bullet_list | ordered_list )+",
      attrs: cellAttrs,
      tableRole: "header_cell",
      isolating: true,
      parseDOM: [
        {
          tag: "th",
          getAttrs: (dom) => getCellAttrs(dom as HTMLElement, extraAttrs),
        },
      ],
      toDOM(node) {
        return ["th", setCellAttrs(node, extraAttrs), 0]
      },
    },
  }
}

/**
 * HELPER
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MutableAttrs = { [s: string]: any }

function getCellAttrs(dom: HTMLElement, extraAttrs: MutableAttrs) {
  const widthAttr = dom.getAttribute("data-colwidth")
  const widths =
    widthAttr && /^\d+(,\d+)*$/.test(widthAttr)
      ? widthAttr.split(",").map((s) => Number(s))
      : null
  const colspan = Number(dom.getAttribute("colspan") || 1)
  const result: MutableAttrs = {
    colspan,
    rowspan: Number(dom.getAttribute("rowspan") || 1),
    colwidth: widths && widths.length == colspan ? widths : null,
  }
  for (const prop in extraAttrs) {
    const getter = extraAttrs[prop].getFromDOM
    const value = getter && getter(dom)
    if (value != null) result[prop] = value
  }
  return result
}

function setCellAttrs(node: Node, extraAttrs: MutableAttrs) {
  const attrs: MutableAttrs = {}
  if (node.attrs.colspan != 1) attrs.colspan = node.attrs.colspan
  if (node.attrs.rowspan != 1) attrs.rowspan = node.attrs.rowspan
  if (node.attrs.colwidth)
    attrs["data-colwidth"] = node.attrs.colwidth.join(",")
  for (const prop in extraAttrs) {
    const setter = extraAttrs[prop].setDOMAttr
    if (setter) setter(node.attrs[prop], attrs)
  }
  return attrs
}

const extraAttrs: MutableAttrs = {}

const cellAttrs: MutableAttrs = {
  colspan: { default: 1 },
  rowspan: { default: 1 },
  colwidth: { default: null },
}

for (const prop in extraAttrs) {
  cellAttrs[prop] = { default: extraAttrs[prop].default }
}
