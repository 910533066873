import { Plugin, PluginKey } from "prosemirror-state"

export function scrollMarginPlugin() {
  return new Plugin({
    key: new PluginKey("scrollMargin"),
    props: {
      scrollMargin: 100,
      scrollThreshold: 100,
    },
  })
}
