import { getVersion } from "prosemirror-collab"
import { v4 as uuidv4 } from "uuid"

import type { UUID } from "@/store/UUID"
import { getCitationsState } from "@/store/selectors/citationsSelectors"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { apiSlice } from "@/store/slices/api"
import { createAppThunk } from "@/store/store"

export const citationDropped = createAppThunk(
  "documents/citationDroppedThunk",
  async (_: void, { getState, dispatch }) => {
    const citationSource = getCitationsState(getState())
    if (!citationSource) return

    const editorState = getRevisionEditorState(
      getState(),
      citationSource.revisionId
    )
    if (!editorState) return

    const version = getVersion(editorState)

    const highlightId = uuidv4() as UUID
    dispatch(
      apiSlice.endpoints.createHighlight.initiate({
        chapterId: citationSource.documentId,
        revisionId: citationSource.revisionId,
        version,
        highlight: {
          id: highlightId,
          revisionId: citationSource.revisionId,
          from: citationSource.from,
          to: citationSource.to,
          color: "yellow",
        },
      })
    )
    return highlightId
  }
)
