import { createSelector } from "@reduxjs/toolkit"
import type { Node, Schema } from "prosemirror-model"

import type { UUID } from "@/store/UUID"
import type { RootState } from "@/store/store"

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6
export type HeadingNode = Node & {
  attrs: { level: HeadingLevel; id: string; guid: string }
}

export function getRevisionEditorState(
  state: RootState,
  revisionId?: UUID | null
) {
  return revisionId && state.revisions.revisions[revisionId]?.editorState
}

export function getRevisionDocNode(state: RootState, revisionId: UUID) {
  return getRevisionEditorState(state, revisionId)?.doc
}

export function getRevisionSchema(state: RootState, revisionId: UUID) {
  return getRevisionEditorState(state, revisionId)?.schema
}

/** Returns the headings in the document, in order of appearance. */
export const getDocumentHeadings = createSelector(
  getRevisionDocNode,
  getRevisionSchema,
  (doc: Node | undefined, schema: Schema | undefined) => {
    const headings: HeadingNode[] = []
    doc?.descendants((node) => {
      if (node.type === schema?.nodes["heading"])
        headings.push(node as HeadingNode)
      return false
    })
    return headings
  },
  {
    memoizeOptions: {
      // We want to avoid producing a new array reference if none of the
      // elements have changed, so we implement a basic shallow equality
      // check.
      resultEqualityCheck: (a: HeadingNode[], b: HeadingNode[]) => {
        return a.length === b.length && a.every((elA, i) => b[i] === elA)
      },
    },
  }
)

export type GapHeading = {
  heading: {
    level: HeadingLevel
    content: null
  }
  subheadings: NestedHeadings[]
}

export type LeafHeading = {
  heading: {
    level: HeadingLevel
    content: HeadingNode
  }
  subheadings: null
}

export type NestedHeading = {
  heading: {
    level: HeadingLevel
    content: HeadingNode
  }
  subheadings: NestedHeadings[]
}

export function isGapHeading(
  nestedHeading: NestedHeadings
): nestedHeading is GapHeading {
  return !nestedHeading.heading.content
}

export type NestedHeadings = GapHeading | LeafHeading | NestedHeading

/**
 * Function to create nested headings from a flat list of headings.
 */
function createNestedDocumentHeadings(
  headings: HeadingNode[],
  initialLevel: HeadingLevel = 1
): NestedHeadings[] {
  const [currentHeading, ...remainingHeadings] = headings
  if (!currentHeading) return []

  const [nextHeading] = remainingHeadings

  const { level: currentLevel } = currentHeading.attrs

  if (currentLevel > initialLevel) {
    const nextHeadingAtCurrentLevel = headings.findIndex(
      ({ attrs: { level } }) => level === initialLevel
    )
    const tail =
      nextHeadingAtCurrentLevel && nextHeadingAtCurrentLevel > 0
        ? headings.slice(nextHeadingAtCurrentLevel)
        : []
    const remainingHeadings =
      nextHeadingAtCurrentLevel && nextHeadingAtCurrentLevel > 0
        ? headings.slice(0, nextHeadingAtCurrentLevel)
        : headings
    return [
      {
        heading: { level: initialLevel, content: null },
        subheadings: createNestedDocumentHeadings(
          remainingHeadings,
          (initialLevel + 1) as HeadingLevel
        ),
      },
      ...createNestedDocumentHeadings(tail, initialLevel),
    ]
  }

  if (!nextHeading) {
    return [
      {
        heading: { level: currentLevel, content: currentHeading },
        subheadings: null,
      },
    ]
  }

  const { level: nextLevel } = nextHeading.attrs

  if (nextLevel < currentLevel) {
    return [
      {
        heading: { level: currentLevel, content: currentHeading },
        subheadings: null,
      },
    ]
  }

  if (nextLevel === currentLevel) {
    return [
      {
        heading: { level: currentLevel, content: currentHeading },
        subheadings: null,
      },
      ...createNestedDocumentHeadings(
        remainingHeadings,
        (currentLevel + 1) as HeadingLevel
      ),
    ]
  }

  const nextHeadingAtCurrentLevel = remainingHeadings.findIndex(
    ({ attrs: { level } }) => level === currentLevel
  )
  const nextHeadingAtLowerThanCurrentLevel = remainingHeadings.findIndex(
    ({ attrs: { level } }) => level < currentLevel
  )

  const start = nextHeadingAtCurrentLevel
  const end =
    nextHeadingAtLowerThanCurrentLevel > 0
      ? nextHeadingAtLowerThanCurrentLevel
      : undefined

  const tail =
    nextHeadingAtCurrentLevel && nextHeadingAtCurrentLevel > 0
      ? remainingHeadings.slice(start, end)
      : []

  return [
    {
      heading: { level: currentLevel, content: currentHeading },
      subheadings: createNestedDocumentHeadings(
        remainingHeadings,
        (currentLevel + 1) as HeadingLevel
      ),
    },
    ...createNestedDocumentHeadings(tail, initialLevel),
  ]
}

export function makeGetNestedDocumentHeadings() {
  const getNestedDocumentHeadings = createSelector(
    getDocumentHeadings,
    createNestedDocumentHeadings
  )
  return getNestedDocumentHeadings
}

export function getAtomState(state: RootState, revisionId: UUID) {
  return state.revisions.revisions[revisionId]?.atomState
}

export function getLinkables(state: RootState) {
  return state.revisions.linkables
}
