import { widget } from "@nytimes/react-prosemirror"
import { Fragment, Slice } from "prosemirror-model"
import { Plugin, PluginKey } from "prosemirror-state"
import { dropPoint } from "prosemirror-transform"
import { DecorationSet } from "prosemirror-view"

import { CitationDropTarget } from "@/components/note/widgets/CitationDropTarget"
import { debounce } from "@/utils/debounce"

function eventCoords(event: MouseEvent) {
  return { left: event.clientX, top: event.clientY }
}

export const citationDropKey = new PluginKey("dskrpt/citation-drop")

export function citationDrop() {
  return new Plugin({
    key: citationDropKey,
    state: {
      init() {
        return DecorationSet.empty
      },
      apply(tr, value) {
        const meta = tr.getMeta(citationDropKey)
        if (!meta) return value

        if (meta.type === "drop" || meta.type === "leave") {
          return DecorationSet.empty
        }

        if (meta.type === "dragover") {
          return DecorationSet.create(tr.doc, [
            // @ts-expect-error react-prosemirror requires using
            // a type here that isn't actually exported
            widget(meta.payload, CitationDropTarget, {
              key: "citation-drop-target",
            }),
          ])
        }

        return value
      },
    },
    props: {
      decorations(state) {
        return citationDropKey.getState(state)
      },
      handleDOMEvents: {
        dragleave(view, event) {
          if (
            !(event.relatedTarget instanceof HTMLElement) ||
            !view.dom.contains(event.relatedTarget)
          ) {
            view.dispatch(
              view.state.tr.setMeta(citationDropKey, { type: "leave" })
            )
            return
          }
        },
        dragover: debounce(
          function (view, event) {
            const eventPos = view.posAtCoords(eventCoords(event))
            if (!eventPos) return false
            const $mouse = view.state.doc.resolve(eventPos.pos)

            const insertPos = $mouse.before(1)

            const tr = view.state.tr

            tr.setMeta(citationDropKey, {
              type: "dragover",
              payload: insertPos,
            })

            view.dispatch(tr)
            return false
          },
          50,
          { leading: true, takeLeading: true }
        ),
      },
      handleDrop(view, event, slice, moved) {
        if (moved) return false
        if (
          !view.state.schema.nodes.citation ||
          !view.state.schema.nodes.paragraph
        )
          return false

        const eventPos = view.posAtCoords(eventCoords(event))
        if (!eventPos) return false
        const $mouse = view.state.doc.resolve(eventPos.pos)

        const insertPos = slice
          ? dropPoint(view.state.doc, $mouse.pos, slice) ?? $mouse.pos
          : $mouse.pos

        const tr = view.state.tr

        let $insertPos = view.state.doc.resolve(insertPos)

        if (
          $insertPos.parent.type !== view.state.schema.nodes.paragraph ||
          $insertPos.parent.childCount !== 0
        ) {
          const newBlockPos =
            $insertPos.parent.type === view.state.schema.topNodeType
              ? insertPos
              : $insertPos.after()

          tr.insert(newBlockPos, view.state.schema.nodes.paragraph.create())

          $insertPos = tr.doc.resolve(newBlockPos + 1)
        }

        const wrapping =
          view.state.schema.nodes.citation.contentMatch.findWrapping(
            slice.content.child(0).type
          )

        const wrappingTypes = wrapping ? [...wrapping].reverse() : wrapping
        const wrapped = view.state.schema.nodes.citation.createAndFill(
          null,
          wrappingTypes?.reduce(
            (acc, wrapperType) => Fragment.from(wrapperType.create(null, acc)),
            slice.content
          )
        )

        let fragment = Fragment.from(wrapped)

        // If we're inserting a citation at the very end of the
        // document, add a paragraph after for convenience
        if ($insertPos.after() === tr.doc.nodeSize - 2) {
          fragment = fragment.addToEnd(
            view.state.schema.nodes.paragraph.create()
          )
        }

        tr.replaceRange(
          $insertPos.before(),
          $insertPos.after(),
          new Slice(fragment, 0, 0)
        )
        tr.setMeta(citationDropKey, { type: "drop" })

        view.dispatch(tr)
        return true
      },
    },
  })
}
