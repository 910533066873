import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { UUID } from "@/store/UUID"
import type { FlashcardReportLevel } from "@/types/api.ts"

export type FlashcardLearningState = {
  id: UUID
  revisionId: UUID
  answered: null | FlashcardReportLevel
}

type FlashcardsLearningState = {
  showPopover: boolean
}

export const flashcardsLearningSlice = createSlice({
  name: "flashcardsLearning",
  initialState: {
    showPopover: false,
  } as FlashcardsLearningState,
  reducers: {
    togglePopover(state) {
      state.showPopover = !state.showPopover
    },
    setPopover(state, action: PayloadAction<{ show: boolean }>) {
      state.showPopover = action.payload.show
    },
  },
})
