import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

import { citationDropKey } from "@/plugins/citationDrop/citationDropPlugin"
import type { UUID } from "@/store/UUID"
import { transactionDispatched } from "@/store/thunks/transactionDispatched"

type CitationsState = {
  source: {
    documentId: UUID
    revisionId: UUID
    from: number
    to: number
  } | null
}

const initialState: CitationsState = {
  source: null,
}

export const citationsSlice = createSlice({
  name: "citations",
  initialState,
  reducers: {
    citationDragAborted(state) {
      state.source = null
    },
    citationDragStarted(
      state,
      action: PayloadAction<{
        documentId: UUID
        revisionId: UUID
        from: number
        to: number
      }>
    ) {
      state.source = action.payload
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      transactionDispatched.fulfilled.match,
      (state, action) => {
        const { transaction } = action.meta.arg

        const citationDropMeta = transaction.getMeta(citationDropKey) as
          | { type: "drop" | "dragover" | "leave" }
          | undefined

        if (citationDropMeta?.type === "drop") {
          state.source = null
          return
        }
      }
    )
  },
})
