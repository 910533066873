/*
 * This slice implements the panel stack. The panel stack represents all panels
 * that the user has opened and not yet closed.
 *
 * The stack is implemented as a list of panel states. Each panel state contains
 * the following information:
 * - panelId: A unique identifier for the panel
 * - panelType: The type of the panel (e.g. viewer, editor, etc.)
 * - panelTitle: The title of the panel that is shown in the tab bar
 *
 * We implement the following methods for the panel stack:
 * - loadInitialPanels: Parse the server-side HTML and create the necessary
 *   panel states on the stack
 * - panelAdded: Add a new panel to the stack at a specific index
 * - panelRemoved: Remove a panel from the stack by its panelId
 * - panelsReplace: Replace the entire panel stack with a new list of panel states
 */
import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

import { apiSlice } from "./api.ts"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import type { UUID } from "@/store/UUID"
import { studyFlashcardsClicked } from "@/store/thunks/studyFlashcardsClicked.ts"

export enum PanelType {
  VIEWER = "viewer",
  EDITOR = "editor",
  FLASHCARD_DECK = "flashcarddeck",
  FLASHCARD_STUDY_VIEW = "flashcardstudyview",
  NOTEBOOK = "notebook",
  NOTE = "note",
  COURT_DECISION = "courtdecision",
  STATUTE_SECTION = "section",
  PDF_VIEWER = "pdfviewer",
}

export type PanelsState = {
  showNotepad: boolean
  notepadNoteId: UUID | null
  stack: PanelState[]
  doublePanelLayout: boolean
  activePanelUuid: UUID | null
}

export type PanelName = keyof PanelsState

export type PanelBaseState = {
  panelId: UUID
  panelTitle?: string
  tableOfContents?: boolean
  scrollToBlock?: UUID
  scrollToHighlight?: UUID
  scrollPosition?: number
}

export interface PanelDocumentIdState extends PanelBaseState {
  documentId: UUID
  revisionId?: UUID | null
  panelType:
    | PanelType.VIEWER
    | PanelType.EDITOR
    | PanelType.NOTEBOOK
    | PanelType.NOTE
    | PanelType.FLASHCARD_DECK
    | PanelType.FLASHCARD_STUDY_VIEW
}

export interface PanelDocumentUrlState extends PanelBaseState {
  documentUrl: string
  documentTitle?: string
  panelType:
    | PanelType.COURT_DECISION
    | PanelType.STATUTE_SECTION
    | PanelType.PDF_VIEWER
}

export type PanelState = PanelDocumentIdState | PanelDocumentUrlState

export const panelsSlice = createSlice({
  name: "panels",
  initialState: {
    showNotepad: false,
    notepadNoteId: null,
    stack: [],
    doublePanelLayout: true,
    activePanelUuid: null,
  } as PanelsState,
  reducers: {
    loadInitialPanels(state, action: PayloadAction<PanelsState["stack"]>) {
      state.stack = action.payload
    },
    notepadToggled(state, action: PayloadAction<boolean>) {
      state.showNotepad = action.payload
    },
    notepadNoteIdUpdated(state, action: PayloadAction<UUID | null>) {
      state.notepadNoteId = action.payload
    },
    panelLayoutUpdated(state, action: PayloadAction<boolean>) {
      state.doublePanelLayout = action.payload
    },
    panelClosed(state, action: PayloadAction<{ panelId: UUID }>) {
      state.stack = state.stack.filter(
        (panel) => panel.panelId !== action.payload.panelId
      )
      if (state.activePanelUuid === action.payload.panelId) {
        state.activePanelUuid = null
      }
    },
    panelOpened(
      state,
      action: PayloadAction<{
        panel: PanelState
        index?: number
      }>
    ) {
      // Check if panel is already in the stack. To do so, we need to check
      // whether there is a panel for the documentUrl or documentId already
      // in the stack
      const existingPanel = state.stack.find((panel) => {
        if ("documentUrl" in panel && "documentUrl" in action.payload.panel) {
          return panel.documentUrl === action.payload.panel.documentUrl
        } else if (
          "documentId" in panel &&
          "documentId" in action.payload.panel
        ) {
          return panel.documentId === action.payload.panel.documentId
        } else {
          return false
        }
      })
      if (existingPanel) {
        state.activePanelUuid = existingPanel.panelId
        return
      }
      if (action.payload.index === undefined) {
        state.stack.push(action.payload.panel)
      } else {
        state.stack.splice(action.payload.index, 0, action.payload.panel)
      }
      state.activePanelUuid = action.payload.panel.panelId
    },
    panelsReplaced(state, action: PayloadAction<PanelState>) {
      state.stack = [action.payload]
    },
    panelSelected(
      state,
      action: PayloadAction<{
        panelId: UUID
      }>
    ) {
      const panel = state.stack.find(
        (panel) => panel.panelId === action.payload.panelId
      )
      if (!panel) return
      state.activePanelUuid = panel.panelId
    },
    panelScrollPositionUpdated(
      state,
      action: PayloadAction<{
        panelId: UUID
        scrollPosition: number
      }>
    ) {
      const panel = state.stack.find(
        (panel) => panel.panelId === action.payload.panelId
      )
      if (!panel) return
      panel.scrollPosition = action.payload.scrollPosition
    },
    panelRevisionIdUpdated(
      state,
      action: PayloadAction<{ panelId: UUID; revisionId: UUID }>
    ) {
      const panel = state.stack.find(
        (panel) => panel.panelId === action.payload.panelId
      )
      if (!panel || panel.panelType !== PanelType.VIEWER) return
      // Append revisionId to the URL without reloading the page
      window.history.replaceState(null, "", `?r=${action.payload.revisionId}`)
      panel.revisionId = action.payload.revisionId
    },
    scrollToBlockUnset(state, action: PayloadAction<{ panelId: UUID }>) {
      const panel = state.stack.find(
        (panel) => panel.panelId === action.payload.panelId
      )
      if (!panel) return
      panel.scrollToBlock = undefined
    },
    tableOfContentsToggled(
      state,
      action: PayloadAction<{
        panelId: UUID
        force?: "open" | "close" | null
      }>
    ) {
      const panel = state.stack.find(
        (panel) => panel.panelId === action.payload.panelId
      )
      if (!panel) return

      const force = action?.payload.force
      if (force === "open") {
        panel.tableOfContents = true
      } else if (force === "close") {
        panel.tableOfContents = false
      } else {
        panel.tableOfContents = !panel.tableOfContents
      }
    },
  },
  extraReducers: (builder) => {
    // Extra reducer to listen to studyFlashcardsClicked to set the panels
    builder.addCase(studyFlashcardsClicked.fulfilled, (state, action) => {
      if (!action.payload) return
      state.stack = [
        {
          panelId: generateGuid() as UUID,
          panelType: PanelType.FLASHCARD_STUDY_VIEW,
          documentId: action.payload.sessionId,
          revisionId: null,
          tableOfContents: false,
        },
      ]
    })
    builder.addMatcher(
      apiSlice.endpoints.getChapter.matchFulfilled,
      (state, { payload }) => {
        const { title, id } = payload
        const panel = state.stack.find(
          (panel) =>
            (panel.panelType === PanelType.VIEWER ||
              panel.panelType === PanelType.EDITOR) &&
            panel.documentId === id
        )
        if (panel) panel.panelTitle = title
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getStatuteSection.matchFulfilled,
      (state, { payload }) => {
        const { selectedSectionUuid, sections } = payload
        const section = sections.find(
          (section) => section.uuid === selectedSectionUuid
        )
        if (!section) return

        const { url, title, referer, statuteAbbreviation } = section

        const panel = state.stack.find(
          (panel) =>
            panel.panelType === PanelType.STATUTE_SECTION &&
            panel.documentUrl === url
        )
        if (panel)
          panel.panelTitle = `${referer} ${statuteAbbreviation}${
            title ? " - " : ""
          }${title ? title : ""}`
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getCourtDecision.matchFulfilled,
      (state, { payload }) => {
        const { url, courtName, docketNumber } = payload
        const panel = state.stack.find(
          (panel) =>
            panel.panelType === PanelType.COURT_DECISION &&
            panel.documentUrl === url
        )
        if (panel) panel.panelTitle = `${courtName} - ${docketNumber}`
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getNote.matchFulfilled,
      (state, { payload }) => {
        const { title, id } = payload
        const panel = state.stack.find(
          (panel) =>
            panel.panelType === PanelType.NOTE && panel.documentId === id
        )
        if (panel) panel.panelTitle = title
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getNotebook.matchFulfilled,
      (state, { payload }) => {
        const { title, id } = payload
        const panel = state.stack.find(
          (panel) =>
            panel.panelType === PanelType.NOTEBOOK && panel.documentId === id
        )
        if (panel) panel.panelTitle = title
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getFlashcardDeck.matchFulfilled,
      (state, { payload }) => {
        const { title, id } = payload
        const panel = state.stack.find(
          (panel) =>
            panel.panelType === PanelType.FLASHCARD_DECK &&
            panel.documentId === id
        )
        if (panel) panel.panelTitle = title
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getNotebooks.matchFulfilled,
      (state, { payload }) => {
        const lastUsedNote = payload
          ?.map((notebook) => notebook.notes)
          .flat()
          .find((note) => note.isLastUsed)
        if (lastUsedNote) {
          state.notepadNoteId = lastUsedNote.id
        }
      }
    )
  },
})
