import {
  InputRule,
  ellipsis,
  emDash,
  textblockTypeInputRule,
  wrappingInputRule,
} from "prosemirror-inputrules"
import { Fragment, Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"

import { createQuiz } from "@/commands/quizzes.ts"
import { createTable } from "@/commands/tables.ts"
import { chapterSchema } from "@/schemas/chapter/schema"

const openDoubleQuote = new InputRule(/(?:^|[\s{[(<'"\u2018\u201C])(")$/, "„")
const closeDoubleQuote = new InputRule(/"$/, "“")

const openSingleQuote = new InputRule(/(?:^|[\s{[(<'"\u2018\u201C])(')$/, "‚")
const closeSingleQuote = new InputRule(/'$/, "‘")

export const flashcardInputRules = [
  blockQuoteRule(),
  calloutRule(),
  detailRule(),
  boxedRule(),
  instructionRule(),
  quizRule(),
  tableRule(),
  headingRule(6),
  orderedListRule(),
  bulletListRule(),
  emDash,
  ellipsis,
  openDoubleQuote,
  closeDoubleQuote,
  openSingleQuote,
  closeSingleQuote,
]

export const chapterInputRules = [...flashcardInputRules, stepMarkerRule()]

export function blockQuoteRule(): InputRule {
  return wrappingInputRule(/^\s*>\s$/, chapterSchema.nodes.blockquote)
}

export function calloutRule(): InputRule {
  return wrappingInputRule(/^!!\s$/, chapterSchema.nodes.callout)
}

export function detailRule(): InputRule {
  return wrappingInputRule(/^\.\.\s$/, chapterSchema.nodes.details)
}

export function boxedRule(): InputRule {
  return wrappingInputRule(/^\|\s$/, chapterSchema.nodes.boxed)
}

export function instructionRule(): InputRule {
  return wrappingInputRule(/^\/inst\s$/, chapterSchema.nodes.instruction)
}

export function quizRule(): InputRule {
  return new InputRule(/^\?{2}\s$/, (state, _match, start, end) => {
    const quiz = createQuiz(state.schema)
    const tr = state.tr
    tr.setSelection(
      new TextSelection(tr.doc.resolve(start), tr.doc.resolve(end))
    )
    tr.replaceSelectionWith(quiz)
    return tr
  })
}

export function tableRule(): InputRule {
  return new InputRule(/^\|{2}\s$/, (state, _match, start, end) => {
    const $start = state.doc.resolve(start)
    const $end = state.doc.resolve(end)
    const selection = new TextSelection($start, $end)
    return state.tr
      .setSelection(selection)
      .replaceSelectionWith(createTable(state))
  })
}

export function orderedListRule(): InputRule {
  return wrappingInputRule(
    /^(\d+)\.\s$/,
    chapterSchema.nodes.ordered_list,
    (match) => ({ order: +(match[1] ?? 0) }),
    (match, node) => node.childCount + node.attrs.order == +(match[1] ?? 0)
  )
}

export function bulletListRule(): InputRule {
  return wrappingInputRule(/^\s*([-+*])\s$/, chapterSchema.nodes.bullet_list)
}

export function headingRule(maxLevel: number): InputRule {
  return textblockTypeInputRule(
    new RegExp("(^.{0,5}#{1," + maxLevel + "})\\s$"),
    chapterSchema.nodes.heading,
    (match) => ({ level: match[1]?.length })
  )
}

export function stepMarkerRule(): InputRule {
  return new InputRule(/^\/st/, (state, _match, start, end) => {
    const $start = state.doc.resolve(start)
    if (
      !$start
        .node(-1)
        .canReplaceWith(
          $start.index(-1),
          $start.indexAfter(-1),
          chapterSchema.nodes.step_marker
        )
    )
      return null

    return state.tr
      .delete(start, end - 1)
      .replace(
        $start.before(),
        $start.after(),
        new Slice(Fragment.from(chapterSchema.nodes.step_marker.create()), 0, 0)
      )
  })
}
