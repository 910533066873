import npmDebug from "debug"

type DebugParameters = Parameters<npmDebug.Debugger>

export const debug =
  (namespace: string) =>
  (formatter: DebugParameters[0], args?: DebugParameters[1]) => {
    const debug = npmDebug(namespace)

    debug.log = console.log.bind(console)

    args ? debug(formatter, args) : debug(formatter)
  }

export const padLogger = debug("padApp")
