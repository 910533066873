import { Plugin, PluginKey, TextSelection } from "prosemirror-state"
import { Mapping } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

import { chapterSchema } from "@/schemas/chapter/schema"
import { type UUID } from "@/store/UUID"
import type { LinkableResourcesResponse } from "@/types/api"
import { positionMatchedTextContent } from "@/utils/prosemirror"

export type ParsedMetaPayload = Array<{
  id: UUID
  start: number
  end: number
  text: string
  referenceType: "court_decision" | "statute_section"
}>

type AllMeta = {
  type: "all"
  payload: Array<{
    id: UUID
  }>
}

type ParsedMeta = {
  type: "parsed"
  payload: ParsedMetaPayload
}

type ClearedMeta = {
  type: "cleared"
  payload: UUID[]
}

export type ReferenceParsingMeta = ParsedMeta | ClearedMeta | AllMeta

export const referenceParsingPluginKey = new PluginKey<Record<UUID, Mapping>>()

/**
 * Produces a new ProseMirror plugin for detecting references to court documents and statutes.
 *
 * The factory takes a `linkables` object with the abbreviations for court types,
 * journals, and statutes. These will be used to build the detector regexes for each
 * type of reference.
 *
 * The plugin adds a `handleTextInput` event listener on the ProseMirror view. On each text input,
 * it scans a 500-character window centered on the input for any references. For each reference
 * found this way, the plugin constructs a Mapping, which it then uses to track each step
 * dispatched until the given reference is explicitly cleared. This mapping can be used to
 * find the originally parsed text in its current location in the document.
 */
export function referenceParsingPlugin(linkables: LinkableResourcesResponse) {
  const StatuteSectionExtractor = {
    startBoundary: "§§?|Art\\.",
    endBoundary: `${linkables.statute_abbrs.join("|")}`,
    referenceType: "statute_section" as const,
  }

  const CourtDecisionExtractor = {
    startBoundary: `(?:${linkables.court_types.join(
      "|"
    )})\\s+(?:${linkables.journal_abbrs.join(
      "|"
    )})|(?:${linkables.journal_abbrs.join("|")})`,
    endBoundary: "(\\d+,\\s*\\d+(?:-\\d+)?(,\\s*\\d+(?:-\\d+)?)*)",
    referenceType: "court_decision" as const,
  }

  const extractors = [StatuteSectionExtractor, CourtDecisionExtractor]

  const MAX_MATCH = 500

  function run(view: EditorView, from: number, text: string) {
    if (view.composing) return false
    const state = view.state
    const $from = state.doc.resolve(from)
    if ($from.parent.type.spec.code) return false

    const searchStart = Math.max(0, $from.parentOffset - MAX_MATCH / 2)
    const searchEnd = Math.min(
      $from.parent.nodeSize - 2,
      $from.parentOffset + MAX_MATCH / 2
    )

    const parentText = positionMatchedTextContent($from.parent)

    const textAround =
      parentText.slice(searchStart, $from.parentOffset) +
      text +
      parentText.slice($from.parentOffset, searchEnd)

    const docPos = from - ($from.parentOffset - searchStart)

    const tr = state.tr
    const references: ParsedMetaPayload = []
    for (const extractor of extractors) {
      const regex = new RegExp(
        `((?:${extractor.startBoundary})(?:(?:.(?!${extractor.startBoundary}))*?(${extractor.endBoundary}))+)\\W`,
        "gdi"
      )

      let matches

      while ((matches = regex.exec(textAround))) {
        const match = matches[1]
        if (!match) continue
        const [, matchEnd] = matches.indices?.[1] ?? []
        if (!matchEnd) continue

        const end = matchEnd + docPos
        const start = end - match.length

        references.push({
          id: crypto.randomUUID() as UUID,
          start,
          end,
          text: match,
          referenceType: extractor.referenceType,
        })
      }
    }

    if (!references.length) {
      return false
    }

    tr.setMeta(referenceParsingPluginKey, {
      type: "parsed",
      payload: references,
    })

    if (text) {
      tr.insert(from, chapterSchema.text(text))
    }

    view.dispatch(tr)
    return true
  }

  return new Plugin<Record<UUID, Mapping>>({
    key: referenceParsingPluginKey,
    state: {
      init() {
        return {}
      },
      apply(this: Plugin, tr, prev) {
        const meta = tr.getMeta(this) as ReferenceParsingMeta
        const next = { ...prev }
        for (const mapping of Object.values(next)) {
          mapping.appendMapping(tr.mapping)
        }
        if (!meta) {
          return next
        }

        if (meta.type === "cleared") {
          for (const cleared of meta.payload) {
            delete next[cleared]
          }
          return next
        }

        for (const { id } of meta.payload) {
          next[id] = new Mapping()
        }
        return next
      },
    },
    props: {
      handleTextInput(view, from, _to, text) {
        return run(view, from, text)
      },
      handleDOMEvents: {
        compositionend(view) {
          setTimeout(() => {
            const { selection } = view.state
            if (!(selection instanceof TextSelection)) return

            const { $cursor } = selection
            if ($cursor) run(view, $cursor.pos, "")
          })
        },
      },
    },
  })
}
