import { Schema } from "prosemirror-model"

import { foldableSchema } from "@/components/editor/nodeViews/Foldable/Foldable.schema"
import { footnoteSchema } from "@/components/editor/nodeViews/Footnote/Footnote.schema"
import { pdfSchema } from "@/components/editor/nodeViews/PDF/PDF.schema"
import { quizSchema } from "@/components/editor/nodeViews/Quiz/Quiz.schema"
import { reviewSchemaSchema } from "@/components/editor/nodeViews/ReviewSchema/ReviewSchema.schema"
import { stepMarkerSchema } from "@/components/editor/nodeViews/StepMarker/StepMarker.schema.ts"
import { tableSchema } from "@/components/editor/nodeViews/Table/Table.schema"
import { deprecatedMentionSchema } from "@/components/viewer/notes/Mention.schema"
import {
  blockquoteSchema,
  boxedSchema,
  calloutSchema,
  definitionSchema,
  detailsSchema,
  embedSchema,
  formattingSchema,
  headingSchema,
  imageSchema,
  importWarningSchema,
  instructionSchema,
  linkSchema,
  listSchema,
  paragraphSchema,
  textSchema,
} from "@/schemas/base-schema"

export const nodes = {
  doc: {
    content: "block+",
    attrs: {
      marginNumbers: { default: undefined },
      headingStyles: {
        default: {
          h1: "upper-alpha",
          h2: "upper-roman",
          h3: "decimal",
          h4: "lower-alpha",
          h5: "lower-alpha-double",
          h6: "lower-roman",
          h7: "lower-bracketed-alpha",
          h8: "lower-bracketed-alpha-double",
          h9: "lower-bracketed-alpha-triple",
        },
      },
      type: { default: "chapter" },
    },
  },

  ...paragraphSchema(),
  ...foldableSchema(),
  ...blockquoteSchema(),
  ...calloutSchema(),
  ...detailsSchema(),
  ...boxedSchema(),
  ...instructionSchema(),
  ...headingSchema(),
  ...textSchema(),
  ...footnoteSchema(),
  ...listSchema(),
  ...tableSchema(),
  ...imageSchema(),
  ...pdfSchema(),
  ...quizSchema(),
  ...embedSchema(),
  ...reviewSchemaSchema(),
  ...definitionSchema(),
  ...importWarningSchema(),
  ...stepMarkerSchema(),
}

export const marks = {
  ...linkSchema(),
  ...formattingSchema(),
  ...deprecatedMentionSchema(),
}

// Schema
// This schema roughly corresponds to the document schema used by
// [CommonMark](http://commonmark.org/), minus the list elements,
// which are defined in the [`prosemirror-schema-list`](#schema-list)
// module.
//
// To reuse elements from this schema, extend or read from its
// `spec.nodes` and `spec.marks` [properties](#model.Schema.spec).
export const chapterSchema = new Schema({ nodes: nodes, marks: marks })
