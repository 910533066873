import type { Fragment } from "prosemirror-model"
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state"
import type { ReplaceStep } from "prosemirror-transform"
import { v4 as uuidv4 } from "uuid"

import type { UUID } from "@/store/UUID"
import { isAttributeAllowed } from "@/utils/prosemirror"
import { getModifiedStartAndEnd } from "@/utils/transaction"

export const generateGuid = (): UUID => {
  return uuidv4() as UUID
}

export function guid() {
  return new Plugin({
    key: new PluginKey("guid"),

    appendTransaction(transactions, oldState, newState) {
      if (oldState.doc.eq(newState.doc)) return

      let { start, end } = getModifiedStartAndEnd(transactions)
      if (!start || !end) return

      // Edge case: If content with guid is inserted
      // into the same doc, we make sure ids stay unique.
      if (containsInsertedContentWithGuid(transactions)) {
        start = 0
        end = newState.doc.content.size
      }

      const tr = ensureGuids({ state: newState, range: { start, end } })

      if (tr.steps.length === 0) return

      return tr
    },
  })
}

export function ensureGuids({
  state,
  range,
}: {
  state: EditorState
  range?: { start: number; end: number }
}) {
  const { tr, schema, doc } = state

  const from = range?.start ?? 0
  const to = range?.end ?? doc.content.size

  const visitedGuids = new Set<UUID>()

  doc.nodesBetween(from, to, (node, pos) => {
    if (!isAttributeAllowed({ schema, node, attribute: "guid" })) return

    if (node.attrs["guid"]) {
      // Ensure uniqueness of guids if nodes were split
      if (visitedGuids.has(node.attrs["guid"])) {
        tr.setNodeAttribute(pos, "guid", generateGuid())
      } else {
        visitedGuids.add(node.attrs["guid"])
      }
    } else {
      tr.setNodeAttribute(pos, "guid", generateGuid())
    }
  })

  return tr
}

function containsGuid(fragment?: Fragment) {
  let guid = false

  fragment?.descendants((node) => {
    if (node.attrs?.["guid"]) {
      guid = true
      return false
    } else {
      return true
    }
  })

  return guid
}

function containsInsertedContentWithGuid(trs: readonly Transaction[]) {
  return trs.some((tr) => {
    return tr.steps.some((step) => {
      return containsGuid((step as ReplaceStep)?.slice?.content)
    })
  })
}
