import { createReducer } from "@reduxjs/toolkit"
import { enqueueSnackbar as addToStack, closeSnackbar } from "notistack"

import { apiSlice } from "@/store/slices/api"

export type SnackbarConfig = Parameters<typeof addToStack>[1] & {
  fluentId: string
  message: string
  beforeShow?: () => void
  actions?: {
    primary: {
      fluentId: string
      label: string
      onClick: () => void
    }
  }
}

export const Snackbar = {
  CouldNotCreateMessage: {
    fluentId: "could-not-create-message",
    message: "Could not create message",
    variant: "error",
  },
  CouldNotUpdateMessage: {
    fluentId: "could-not-update-message",
    message: "Could not save message",
    variant: "error",
  },
  CouldNotDeleteMessage: {
    fluentId: "could-not-delete-message",
    message: "Could not delete message",
    variant: "error",
  },
  CouldNotCreateHighlight: {
    fluentId: "could-not-create-highlight",
    message: "Could not create highlight",
    variant: "error",
  },
  CouldNotDeleteHighlight: {
    fluentId: "could-not-delete-highlight",
    message: "Could not delete highlight",
    variant: "error",
  },
  CouldNotUploadFile: {
    fluentId: "could-not-upload-file",
    message: "Could not upload file",
    variant: "error",
  },
  LostWebsocketConnection: {
    fluentId: "lost-websocket-connection",
    message: "Lost connection. Trying to reconnect...",
    variant: "error",
    preventDuplicate: true,
    persist: true,
  },
  ReconnectWebsocket: {
    fluentId: "reconnected-websocket",
    message: "Connected to server.",
    variant: "default",
    preventDuplicate: true,
    beforeShow: () => {
      closeSnackbar() // Close `LostWebsocketConnection` snackbar
    },
  },
  ChapterTooLong: {
    fluentId: "importer-chapter-too-long",
    message:
      "One or more chapters are too long to be imported. Please split them.",
    variant: "error",
  },
  ChapterTitleMissing: {
    fluentId: "importer-chapter-title-missing",
    message: "One or more chapters are missing a title. Please add a title.",
    variant: "error",
  },
  NoSplitPoints: {
    fluentId: "importer-no-split-points",
    message: "No split points found.",
    variant: "error",
  },
  LinkCopiedSuccess: {
    fluentId: "link-copied-success",
    message: "Link copied to clipboard!",
    variant: "success",
    autoHideDuration: 2000,
  },
  LinkCopiedError: {
    fluentId: "link-copied-error",
    message: "Could not copy link.",
    variant: "error",
    autoHideDuration: 2000,
  },
  MarginNumbersSetSuccess: {
    fluentId: "margin-numbers-set-success",
    message: "Inserted margin numbers!",
    variant: "success",
  },
  MarginNumbersSetError: {
    fluentId: "margin-numbers-set-error",
    message: "Could not insert margin numbers.",
    variant: "error",
  },
  FlashcardCreatedError: {
    fluentId: "flashcard-created-error",
    message: "Could not create flashcard.",
    variant: "error",
  },
  FlashcardDeletedSuccess: {
    fluentId: "flashcard-deleted-success",
    message: "Flashcard deleted successfully.",
    variant: "error",
  },
  FlashcardDeletedError: {
    fluentId: "flashcard-deleted-error",
    message: "Could not delete flashcard.",
    variant: "error",
  },
  NoteUpdatedError: {
    fluentId: "note-frontmatter-updated-error",
    message: "Could not update note.",
    variant: "error",
  },
} as const

export const enqueueSnackbar = ({
  message,
  beforeShow,
  ...other
}: SnackbarConfig) => {
  beforeShow?.()
  return addToStack(message, other)
}

/**
 * This reducer only listens for actions dispatched in other slices.
 * It does not own any slice data itself since the snackbar state is
 * managed by notistack internally.
 */

export const snackbarsReducer = createReducer(null, (builder) => {
  builder.addMatcher(apiSlice.endpoints.createMessage.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotCreateMessage)
  })
  builder.addMatcher(apiSlice.endpoints.updateMessage.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotUpdateMessage)
  })
  builder.addMatcher(apiSlice.endpoints.deleteMessage.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotDeleteMessage)
  })
  builder.addMatcher(apiSlice.endpoints.createHighlight.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotCreateHighlight)
  })
  builder.addMatcher(apiSlice.endpoints.deleteHighlight.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotDeleteHighlight)
  })
  builder.addMatcher(apiSlice.endpoints.uploadFile.matchRejected, () => {
    enqueueSnackbar(Snackbar.CouldNotUploadFile)
  })
  builder.addMatcher(apiSlice.endpoints.updateNote.matchRejected, () => {
    enqueueSnackbar(Snackbar.NoteUpdatedError)
  })
})
