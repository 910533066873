import { reactKeys } from "@nytimes/react-prosemirror"
import { type PayloadAction, createSlice } from "@reduxjs/toolkit"
import { castDraft } from "immer"
import { collab, receiveTransaction } from "prosemirror-collab"
import { gapCursor } from "prosemirror-gapcursor"
import { history } from "prosemirror-history"
import { inputRules } from "prosemirror-inputrules"
import type { Node, ResolvedPos } from "prosemirror-model"
import {
  EditorState,
  NodeSelection,
  Plugin,
  TextSelection,
  type Transaction,
} from "prosemirror-state"
import { columnResizing, tableEditing } from "prosemirror-tables"
import { Step, StepMap } from "prosemirror-transform"
import { useCallback, useMemo } from "react"

import { apiSlice } from "./api"
import { Dialog } from "./dialogs"

import { chapterInputRules, flashcardInputRules } from "@/components/InputRules"
import { guid } from "@/components/editor/plugins/guid"
import { marginNumbers } from "@/components/editor/plugins/marginNumbers"
import { citationDrop } from "@/plugins/citationDrop/citationDropPlugin"
import {
  referenceParsingPlugin,
  referenceParsingPluginKey,
} from "@/plugins/referenceParsing/referenceParsingPlugin"
import { scrollMarginPlugin } from "@/schemas/chapter/createEditorState"
import { chapterSchema } from "@/schemas/chapter/schema"
import { flashcardSchema } from "@/schemas/flashcard/schema"
import { noteSchema } from "@/schemas/note/schema"
import type { UUID } from "@/store/UUID"
import { clientID } from "@/store/clientID"
import { useAppDispatch } from "@/store/hooks"
import { dialogOpened, store } from "@/store/store"
import { transactionDispatched } from "@/store/thunks/transactionDispatched"
import { transactionSendTriggered } from "@/store/thunks/transactionSendTriggered"
import type { DocumentRevision, LinkableResourcesResponse } from "@/types/api"
import { sendErrorToSentry } from "@/utils/sentry"

type RevisionState = Omit<DocumentRevision, "snapshot"> & {
  /** Parsed editor state of the outer editor */
  editorState?: EditorState
  /** Parsed editor state of the inner editor (e.g. used for footnotes) */
  atomState?: EditorState
}

type FootnoteState = {
  visible?: boolean
  number?: number
}

type RevisionsState = {
  revisions: Record<UUID, RevisionState>
  footnotes: Record<UUID, FootnoteState>
  linkables?: LinkableResourcesResponse
}

export function getChapterPlugins(
  version: number | undefined,
  linkables: LinkableResourcesResponse | undefined
) {
  return [
    collab({ clientID, version }),
    guid(),
    marginNumbers(),
    history(),
    gapCursor(),
    columnResizing(),
    tableEditing(),
    ...(linkables ? [referenceParsingPlugin(linkables)] : []),
    inputRules({ rules: chapterInputRules }),
    scrollMarginPlugin(),
    reactKeys(),
  ]
}

export function getNotePlugins(
  version: number | undefined,
  linkables: LinkableResourcesResponse | undefined
) {
  return [
    collab({ clientID, version }),
    guid(),
    marginNumbers(),
    history(),
    gapCursor(),
    columnResizing(),
    tableEditing(),
    ...(linkables ? [referenceParsingPlugin(linkables)] : []),
    inputRules({ rules: chapterInputRules }),
    scrollMarginPlugin(),
    citationDrop(),
    reactKeys(),
  ]
}

/**
 * The documents slice contains all the state logic for the editor.
 * While the api slice fetches the data, the `documents` slice works
 * with the parsed editor state.
 */

export const revisionsSlice = createSlice({
  name: "revisions",
  initialState: {
    revisions: {},
    footnotes: {},
  } as RevisionsState,
  reducers: {
    transactionDispatched(
      state,
      action: PayloadAction<
        | {
            revisionId: UUID
            transaction: Transaction
            fromAtom?: false
          }
        | {
            revisionId: UUID
            transaction: Transaction
            fromAtom: true
            atomPos: number
          }
      >
    ) {
      const { payload } = action

      const revision = state.revisions[payload.revisionId]
      if (!revision?.editorState) return

      let outerTransaction = payload.transaction

      if (payload.fromAtom) {
        outerTransaction = revision.editorState.tr as unknown as Transaction
        outerTransaction.setSelection(
          new NodeSelection(revision.editorState.doc.resolve(payload.atomPos))
        )

        for (const step of payload.transaction.steps) {
          const mappedStep = step.map(
            StepMap.offset(outerTransaction.selection.from + 1)
          )
          if (mappedStep) {
            outerTransaction.step(mappedStep)
          }
        }

        if (
          revision.atomState &&
          payload.transaction.before.eq(
            revision.atomState.doc as unknown as Node
          )
        ) {
          revision.atomState = castDraft(
            revision.atomState.apply(payload.transaction)
          )
        } else {
          revision.atomState = castDraft(
            EditorState.create({
              doc: payload.transaction.doc,
              selection: payload.transaction.selection,
            })
          )
        }
      }

      const footnoteId = outerTransaction.getMeta("footnoteInserted") as
        | UUID
        | undefined

      if (footnoteId) {
        state.footnotes[footnoteId] = {
          ...state.footnotes[footnoteId],
          visible: !state.footnotes[footnoteId]?.visible,
        }

        const outerSelection = outerTransaction.selection
        if (outerSelection instanceof NodeSelection) {
          revision.atomState = castDraft(
            EditorState.create({
              doc: outerSelection.node,
              selection: new TextSelection(
                outerSelection.node.resolve(outerSelection.node.nodeSize - 2)
              ),
            })
          )
        }
      }

      const previousValue = revision.editorState
      revision.editorState = castDraft(previousValue.apply(outerTransaction))

      if (outerTransaction.selectionSet && !payload.fromAtom) {
        const { selection } = outerTransaction
        if (
          !(selection instanceof NodeSelection) ||
          selection.node !== revision.atomState?.doc
        ) {
          delete revision.atomState
        }
      }
    },
    remoteTransactionReceived(
      state,
      action: PayloadAction<{ revisionId: UUID; transaction: Transaction }>
    ) {
      const { revisionId, transaction } = action.payload

      const revision = state.revisions[revisionId]
      if (!revision?.editorState) return

      const previousValue = revision.editorState
      revision.editorState = castDraft(previousValue.apply(transaction))
    },
    /**
     * Spawns an inner editor for the given atom node (e.g. used for footnotes).
     * The inner editor then applies any changes back to the outer editor.
     */
    atomNodeActivated(
      state,
      action: PayloadAction<{ revisionId: UUID; pos: number }>
    ) {
      const { payload } = action

      const revision = state.revisions[payload.revisionId]
      if (!revision?.editorState) return

      const $nodePos = revision.editorState.doc.resolve(payload.pos)
      const nextSelection = new NodeSelection($nodePos)

      if (nextSelection.node.isLeaf || !nextSelection.node.isAtom) {
        return
      }

      const outerTransaction =
        revision.editorState.tr.setSelection(nextSelection)

      revision.editorState = castDraft(
        revision.editorState.apply(outerTransaction)
      )

      revision.atomState = castDraft(
        EditorState.create({
          doc: nextSelection.node,
          selection: new TextSelection(
            nextSelection.node.resolve(nextSelection.node.nodeSize - 2)
          ),
          ...(state.linkables && {
            plugins: [referenceParsingPlugin(state.linkables)],
          }),
        })
      )
    },
    setFootnoteNumbers(
      state,
      action: PayloadAction<{ guid: UUID; number: number }[]>
    ) {
      for (const { guid, number } of action.payload) {
        state.footnotes[guid] = {
          ...state.footnotes[guid],
          number,
        }
      }
    },
    toggleFootnoteVisibility(
      state,
      action: PayloadAction<{ footnoteId: UUID }>
    ) {
      const { footnoteId } = action.payload
      state.footnotes[footnoteId] = {
        ...state.footnotes[footnoteId],
        visible: !state.footnotes[footnoteId]?.visible,
      }
    },
    toggleAllFootnoteVisibility(
      state,
      action: PayloadAction<{ footnoteIds: UUID[] }>
    ) {
      const footnotes = action.payload.footnoteIds.reduce((acc, guid) => {
        return state.footnotes[guid]
          ? [...acc, { ...state.footnotes[guid], guid }]
          : acc
      }, [] as { guid: UUID; visible?: boolean }[])

      const hasOpenFootnotes = footnotes.some((footnote) => footnote.visible)

      footnotes.forEach((footnote) => {
        state.footnotes[footnote.guid] = {
          ...state.footnotes[footnote.guid],
          visible: !hasOpenFootnotes,
        }
      })
    },
    toggleReferenceParsing(state, action: PayloadAction<{ revisionId: UUID }>) {
      const { revisionId } = action.payload
      const revision = state.revisions[revisionId]
      const editorState = revision?.editorState
      if (!revision || !editorState || !state.linkables) return

      const hasReferenceParsingPlugin = !!referenceParsingPluginKey.getState(
        editorState as unknown as EditorState
      )

      const nextPlugins = hasReferenceParsingPlugin
        ? editorState.plugins.filter(
            ({ spec: { key } }) => key !== referenceParsingPluginKey
          )
        : [referenceParsingPlugin(state.linkables), ...editorState.plugins]

      revision.editorState = castDraft(
        editorState.reconfigure({
          plugins: nextPlugins as Plugin[],
        })
      )
    },
  },
  extraReducers: (builder) => {
    // Parse the editor state upon getting a chapter revision
    builder.addMatcher(
      apiSlice.endpoints.getChapterRevision.matchFulfilled,
      (state, { payload }) => {
        if (!state.revisions[payload.id]) {
          state.revisions[payload.id] = {
            id: payload.id,
            documentId: payload.documentId,
            status: payload.status,
            name: payload.name,
            editorState: castDraft(
              EditorState.create({
                schema: chapterSchema,
                plugins: getChapterPlugins(
                  payload.snapshot?.version,
                  state.linkables
                ),
                doc:
                  payload.snapshot?.content &&
                  chapterSchema.nodeFromJSON(payload.snapshot.content),
              })
            ),
          }
        }
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.getNoteRevision.matchFulfilled,
      (state, { payload }) => {
        if (!state.revisions[payload.id]) {
          state.revisions[payload.id] = {
            id: payload.id,
            documentId: payload.documentId,
            status: payload.status,
            name: payload.name,
            editorState: castDraft(
              EditorState.create({
                schema: chapterSchema,
                plugins: getNotePlugins(
                  payload.snapshot?.version,
                  state.linkables
                ),
                doc:
                  payload.snapshot?.content &&
                  noteSchema.nodeFromJSON(payload.snapshot.content),
              })
            ),
          }
        }
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.getFlashcardRevision.matchFulfilled,
      (state, { payload }) => {
        if (!state.revisions[payload.id]) {
          state.revisions[payload.id] = {
            id: payload.id,
            documentId: payload.documentId,
            status: payload.status,
            name: payload.name,
            editorState: castDraft(
              EditorState.create({
                schema: flashcardSchema,
                plugins: [
                  collab({ clientID, version: payload.snapshot?.version }),
                  history(),
                  ...(state.linkables
                    ? [referenceParsingPlugin(state.linkables)]
                    : []),
                  inputRules({ rules: flashcardInputRules }),
                  reactKeys(),
                ],
                doc:
                  payload.snapshot?.content &&
                  flashcardSchema.nodeFromJSON(payload.snapshot.content),
              })
            ),
          }
        }
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getLinkableResources.matchFulfilled,
      (state, { payload }) => {
        for (const revision of Object.values(state.revisions)) {
          const prevState = revision.editorState
          if (!prevState) continue
          revision.editorState = castDraft(
            prevState.reconfigure({
              plugins: [
                referenceParsingPlugin(payload),
                ...(prevState.plugins as Plugin[]),
              ],
            })
          )
          const prevAtomState = revision.atomState
          if (!prevAtomState) continue
          revision.atomState = castDraft(
            prevAtomState.reconfigure({
              plugins: [
                referenceParsingPlugin(payload),
                ...(prevAtomState.plugins as Plugin[]),
              ],
            })
          )
        }
        state.linkables = payload
      }
    )
    // Set the selection after a highlight is created
    builder.addMatcher(
      apiSlice.endpoints.createHighlight.matchFulfilled,
      (state, { payload }) => {
        const revision = state.revisions[payload.revisionId]
        const editorState = revision?.editorState
        if (!editorState) return

        revision.editorState = castDraft(
          editorState.apply(
            editorState.tr.setSelection(
              new TextSelection(editorState.selection.$anchor as ResolvedPos)
            )
          )
        )
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.getFlashcardLatestSteps.matchFulfilled,
      (state, action) => {
        const revision =
          state.revisions[action.meta.arg.originalArgs.revisionId]
        const editorState = revision?.editorState

        if (!editorState) return

        const transaction = receiveTransaction(
          editorState as unknown as EditorState,
          action.payload.map(({ value }) => value),
          action.payload.map(({ client_id }) => client_id)
        )

        revision.editorState = castDraft(editorState.apply(transaction))
      }
    )
  },
})

/** HELPERS */

export function useRemoteSteps({
  documentType,
  documentId,
  revisionId,
}: {
  documentType: "chapter" | "flashcard" | "note"
  documentId: UUID
  revisionId?: UUID
}) {
  const dispatch = useAppDispatch()

  const onReceiveSteps = useCallback(
    ({ steps, clientIDs }: { steps: Step[]; clientIDs: UUID[] }) => {
      if (!revisionId) return

      const state = store.getState()
      const editorState = state.revisions.revisions[revisionId]?.editorState
      if (!editorState) return

      try {
        const transaction = receiveTransaction(editorState, steps, clientIDs)

        dispatch(
          revisionsSlice.actions.remoteTransactionReceived({
            revisionId,
            transaction,
          })
        )
      } catch (e) {
        sendErrorToSentry(`Could not apply remote steps`, e)
      } finally {
        // After receiving remote steps, it's important that we
        // always attempt to send any sendable steps again, in case
        // we've rebased and have new steps to send
        dispatch(
          transactionSendTriggered({ documentType, documentId, revisionId })
        )
      }
    },
    [dispatch, documentId, documentType, revisionId]
  )

  const onConnectionError = useCallback(() => {
    dispatch(dialogOpened(Dialog.ERROR_CONNECTION_LOST))
  }, [dispatch])

  const handlers = useMemo(
    () => ({ onReceiveSteps, onConnectionError }),
    [onConnectionError, onReceiveSteps]
  )

  return handlers
}

export function useDispatchTransaction({
  documentType,
  documentId,
  revisionId,
}: {
  documentType: "chapter" | "flashcard" | "note"
  documentId: UUID
  revisionId?: UUID | null
}) {
  const dispatch = useAppDispatch()

  return useCallback(
    (transaction: Transaction) => {
      if (revisionId)
        dispatch(
          transactionDispatched({
            documentType,
            documentId,
            revisionId,
            transaction,
          })
        )
    },
    [dispatch, documentId, documentType, revisionId]
  )
}
