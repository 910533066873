import type { Node } from "prosemirror-model"
import { type EditorState, TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

import { chapterSchema } from "@/schemas/chapter/schema"

export function insertQuiz(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (
    !(
      state.schema.nodes["quiz"] &&
      state.schema.nodes["quiz_answer"] &&
      state.schema.nodes["quiz_answers"] &&
      state.schema.nodes["quiz_question"] &&
      state.schema.nodes["quiz_explanation"]
    )
  ) {
    return false
  }

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["quiz"]
  )

  if (validInsertPoint === null) return false

  if (dispatch) {
    const { tr } = state
    tr.insert(validInsertPoint, createQuiz(state.schema))
    tr.setSelection(TextSelection.near(tr.doc.resolve(validInsertPoint + 1)))
    dispatch(tr)
  }
  return true
}

export function createQuiz(_: EditorState["schema"]) {
  const question = chapterSchema.nodes["quiz_question"].create()
  const answerList = [
    chapterSchema.nodes["quiz_answer"].create(),
    chapterSchema.nodes["quiz_answer"].create(),
    chapterSchema.nodes["quiz_answer"].create(),
  ]
  const answers = chapterSchema.nodes["quiz_answers"].create(null, answerList)
  const explanation = chapterSchema.nodes["quiz_explanation"].create()
  return chapterSchema.nodes["quiz"].create({}, [
    question,
    answers,
    explanation,
  ])
}

export function addAnswerToQuiz(pos?: number) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    if (!state.schema.nodes["quiz_answer"]) return false

    const answer = state.schema.nodes["quiz_answer"].create()
    const { tr, doc, selection, schema } = state

    const $pos = pos !== undefined ? doc.resolve(pos) : selection.$from

    if ($pos.node().type !== schema.nodes["quiz_answer"]) return false
    const { pos: insertPos } = $pos.doc.resolve($pos.after())

    dispatch?.(
      tr
        .insert(insertPos, answer)
        .setSelection(TextSelection.near(tr.doc.resolve(insertPos + 1)))
    )

    return true
  }
}

export function removeAnswerFromQuiz(node: Node, pos: number) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    dispatch?.(state.tr.delete(pos, pos + node.nodeSize + 1))
    return true
  }
}

export function setAnswerIsCorrect(pos: number, isCorrect: boolean) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    dispatch?.(state.tr.setNodeAttribute(pos, "isCorrect", isCorrect))
    return true
  }
}

export function selectPreviousQuizItem(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  const node = $from.node()

  if (
    ![
      state.schema.nodes["quiz_answer"],
      state.schema.nodes["quiz_explanation"],
      state.schema.nodes["quiz_question"],
    ].includes(node.type)
  ) {
    return false
  }

  if (dispatch) {
    const nodeBefore =
      $from.doc.resolve($from.before()).nodeBefore ??
      $from.doc.resolve($from.before(-1)).nodeBefore
    const start =
      nodeBefore?.type === state.schema.nodes["quiz_answer"]
        ? $from.before() - 1
        : $from.before() - 2

    dispatch(state.tr.setSelection(TextSelection.create(state.doc, start)))
  }
  return true
}

export function selectNextQuizItem(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  const node = $from.node()

  if (
    ![
      state.schema.nodes["quiz_answer"],
      state.schema.nodes["quiz_explanation"],
      state.schema.nodes["quiz_question"],
    ].includes(node.type)
  ) {
    return false
  }

  if (dispatch) {
    const nodeAfter =
      $from.doc.resolve($from.after()).nodeAfter ??
      $from.doc.resolve($from.after($from.depth + 1)).nodeAfter ??
      $from.doc.resolve($from.after($from.depth - 1)).nodeAfter

    const start =
      nodeAfter?.type === state.schema.nodes["quiz_answer"]
        ? $from.after() + 1
        : $from.after() + 2

    dispatch(state.tr.setSelection(TextSelection.create(state.doc, start)))
  }
  return true
}

export function selectParentQuiz(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  const node = $from.node()
  const parent = $from.node(-1)

  if (!node || !parent) return false

  if (
    node.type !== state.schema.nodes["quiz_answer"] &&
    node.type !== state.schema.nodes["review_item_title"] &&
    parent.type !== state.schema.nodes["review_item_content"]
  ) {
    return false
  }

  if (dispatch) {
    dispatch(
      state.tr.setSelection(
        TextSelection.create(
          state.doc,
          $from.start(),
          $from.start() + node.nodeSize - 2
        )
      )
    )
  }
  return true
}
