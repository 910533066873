import { transactionDispatched } from "./transactionDispatched"

import type { UUID } from "@/store/UUID"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { getFlashcardId } from "@/store/selectors/flashcardSelectors"
import { apiSlice } from "@/store/slices/api"
import { flashcardsSlice } from "@/store/slices/flashcards"
import { createAppThunk } from "@/store/store"

const flashcardInitiated = createAppThunk(
  "flashcards/flashcardInitiated",
  async (
    {
      flashcardId,
      flashcardRevisionId,
      highlightId,
      chapterId,
      revisionId,
      magic,
    }: {
      flashcardId: UUID
      highlightId: UUID
      flashcardRevisionId: UUID | undefined
      chapterId: UUID
      revisionId: UUID
      magic: boolean
    },
    { getState, dispatch }
  ) => {
    if (!flashcardRevisionId) return

    // Ensure that the revision has been loaded
    await dispatch(
      apiSlice.endpoints.getFlashcardRevision.initiate(
        {
          flashcardId,
          revisionId: flashcardRevisionId,
        },
        { forceRefetch: false }
      )
    )

    const flashcardEditorState = getRevisionEditorState(
      getState(),
      flashcardRevisionId
    )

    if (flashcardEditorState) {
      const chapterEditorState = getRevisionEditorState(getState(), revisionId)

      const { data: highlights } = await dispatch(
        apiSlice.endpoints.getHighlights.initiate(
          { chapterId, revisionId },
          { forceRefetch: false }
        )
      )
      const highlight = highlights?.find(
        (highlight) => highlight.id === highlightId
      )
      if (!highlight || !chapterEditorState) return

      const text = chapterEditorState?.doc.textBetween(
        highlight.from,
        highlight.to
      )

      if (magic) {
        const response = await dispatch(
          apiSlice.endpoints.createMagicFlashcardText.initiate({ text })
        )
        if (!("data" in response)) return

        const { flashcardFront, flashcardBack } = response.data
        const transaction = flashcardEditorState.tr
          .insertText(flashcardBack, 6)
          .insertText(flashcardFront, 2)

        dispatch(
          transactionDispatched({
            documentType: "flashcard",
            documentId: flashcardId,
            revisionId: flashcardRevisionId,
            transaction,
            fromAtom: false,
          })
        )
        return
      }

      const transaction = flashcardEditorState.tr.insertText(text)
      dispatch(
        transactionDispatched({
          documentType: "flashcard",
          documentId: flashcardId,
          revisionId: flashcardRevisionId,
          transaction,
          fromAtom: false,
        })
      )
    }
  }
)

export const flashcardToggled = createAppThunk(
  "flashcards/flashcardToggledThunk",
  async (
    {
      highlightId,
      chapterId,
      revisionId,
      magic,
    }: { highlightId: UUID; chapterId: UUID; revisionId: UUID; magic: boolean },
    { getState, dispatch }
  ) => {
    const existingFlashcardId = getFlashcardId(
      getState(),
      revisionId,
      highlightId
    )

    if (!existingFlashcardId) {
      let deckId: UUID | null = null
      let { data: allDecks } = await dispatch(
        apiSlice.endpoints.getFlashcardDecks.initiate(undefined, {
          forceRefetch: false,
        })
      )
      allDecks = allDecks?.filter((deck) => deck.canUserEdit)

      const firstDeck = allDecks?.[0]
      if (firstDeck) deckId = firstDeck.id
      else {
        const result = await dispatch(
          apiSlice.endpoints.createFlashcardDeck.initiate("Untitled Deck")
        )
        if (!("data" in result)) {
          throw result.error
        }
        deckId = result.data.id
      }

      if (!deckId) {
        throw new Error("Failed to find or create flashcard deck")
      }

      const result = await dispatch(
        apiSlice.endpoints.addFlashcardToHighlight.initiate({
          revisionId,
          highlightId,
          deckId: deckId,
        })
      )
      if (!("data" in result)) {
        throw result.error
      }

      dispatch(
        flashcardsSlice.actions.flashcardToggled({
          highlightId,
          revisionId,
          flashcardId: result.data.id,
        })
      )

      await dispatch(
        flashcardInitiated({
          flashcardId: result.data.id,
          highlightId,
          flashcardRevisionId: result.data.draftRevisionId,
          revisionId,
          chapterId,
          magic,
        })
      )
    } else {
      dispatch(
        flashcardsSlice.actions.flashcardToggled({
          highlightId,
          revisionId,
          flashcardId: existingFlashcardId,
        })
      )
    }
  }
)
