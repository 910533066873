import { createSlice } from "@reduxjs/toolkit"

import { apiSlice } from "./api"

import type { UUID } from "@/store/UUID"
import type { Note } from "@/types/api"

type NotesState = Record<UUID, Note>

/**
 * The documents slice contains all the state logic for the editor.
 * While the api slice fetches the data, the `documents` slice works
 * with the parsed editor state.
 */

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    notes: {},
    revisions: {},
    footnotes: {},
  } as NotesState,
  reducers: {},
  extraReducers: (builder) => {
    // To access the chapter data inside `documents`
    builder.addMatcher(
      apiSlice.endpoints.getNote.matchFulfilled,
      (state, { payload }) => {
        if (!state[payload.id]) {
          state[payload.id] = payload
        }
      }
    )
  },
})
