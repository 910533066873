import type { UUID } from "@/store/UUID.ts"
import { apiSlice } from "@/store/slices/api.ts"
import { flashcardsLearningSlice } from "@/store/slices/flashcardLearning.ts"
import { createAppThunk } from "@/store/store"

export const studyFlashcardsClicked = createAppThunk(
  "flashcards/study",
  async (
    { deckId, ignoreDueDate }: { deckId: UUID; ignoreDueDate?: boolean },
    { dispatch }
  ) => {
    dispatch(flashcardsLearningSlice.actions.setPopover({ show: false }))
    apiSlice.util.invalidateTags(["StudySession"])
    const res = await dispatch(
      apiSlice.endpoints.createStudySession.initiate({
        deckId,
        ignoreDueDate: ignoreDueDate ?? false,
      })
    )
    if (res.error) {
      dispatch(flashcardsLearningSlice.actions.setPopover({ show: true }))
      return
    }
    const session = res.data
    if (!session) return
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.set("sessionId", session.uuid)
    window.history.pushState({}, "", newUrl.toString())
    return { sessionId: session.uuid }
  }
)
