import { type NodeSpec, Schema } from "prosemirror-model"

import { CommonBlockAttrs } from "@/schemas/base-schema"
import { nodes as chapterNodes, marks } from "@/schemas/chapter/schema"

const { doc, text, paragraph, ...remainingNodes } = chapterNodes

export const nodes = {
  doc: {
    ...doc,
    content: "(block | citation)+",
  },
  text,
  paragraph,
  citation: {
    content: "block+",
    attrs: {
      ...CommonBlockAttrs.spec,
      source_document_id: { default: null },
      annotation_id: { default: null },
    },
    atom: true,
    isolating: true,
    parseDOM: [
      {
        tag: "blockquote[data-node-type='citation']",
        getAttrs: CommonBlockAttrs.parse,
      },
    ],
    toDOM(node) {
      return [
        "blockquote",
        { ...CommonBlockAttrs.serialize(node), "data-node-type": "citation" },
        0,
      ]
    },
  },
  ...remainingNodes,
} satisfies Record<string, NodeSpec>

export const noteSchema = new Schema({ nodes: nodes, marks: marks })
