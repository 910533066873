import quizStyles from "./quiz.module.css"

import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"

export function quizSchema(): NodeSchema<
  "quiz" | "quiz_question" | "quiz_answers" | "quiz_answer" | "quiz_explanation"
> {
  return {
    // NodeSpec A quiz (`<div.quiz>`) node.
    quiz: {
      content: "quiz_question quiz_answers quiz_explanation",
      group: "block",
      atom: true,
      allowGapCursor: false,
      isolating: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: `div.${quizStyles["quizWrapper"]}`,
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return [
          "div",
          {
            ...CommonBlockAttrs.serialize(node),
            "data-type": "quiz",
            class: quizStyles["quizWrapper"],
          },
          0,
        ]
      },
    },

    quiz_question: {
      content: "inline*",
      defining: true,
      parseDOM: [{ tag: `div.${quizStyles["question"]}` }],
      toDOM(_node) {
        return ["div", { class: quizStyles["question"] }, 0]
      },
    },

    quiz_explanation: {
      content: "inline*",
      defining: true,
      parseDOM: [{ tag: `div.${quizStyles["explanation"]}` }],
      toDOM(_node) {
        return ["div", { class: quizStyles["explanation"] }, 0]
      },
    },

    quiz_answers: {
      content: "quiz_answer{2,}",
      parseDOM: [{ tag: `ul.${quizStyles["answers"]}`, priority: 100 }],
      toDOM(_node) {
        return ["ul", { class: quizStyles["answers"] }, 0]
      },
    },

    // NodeSpec A quiz (`<div.quiz>`) node.
    quiz_answer: {
      content: "inline*",
      defining: true,
      attrs: { isCorrect: { default: false } },
      parseDOM: [
        {
          tag: `div.${quizStyles["answer"]}`,
          getAttrs(dom) {
            return (
              dom instanceof HTMLElement && {
                isCorrect: dom.dataset["isCorrect"] === "true",
              }
            )
          },
        },
      ],
      toDOM(node) {
        return [
          "div",
          {
            class: quizStyles["answer"],
            "data-is-correct": node.attrs.isCorrect,
          },
          0,
        ]
      },
    },
  }
}
