import { Localized, useLocalization } from "@fluent/react"
import {
  type CustomContentProps,
  MaterialDesignContent as NotistackSnackbar,
  type SnackbarKey,
} from "notistack"
import { forwardRef, useMemo } from "react"

import styles from "./Snackbar.module.css"

import type { SnackbarConfig } from "@/store/reducers/snackbars"

type SnackbarComponentProps = CustomContentProps &
  Omit<SnackbarConfig, "message">

export const SnackbarComponent = forwardRef<
  HTMLDivElement,
  SnackbarComponentProps
>((props, ref) => {
  const { l10n } = useLocalization()
  const { fluentId, message, actions, action: _action, ...other } = props

  const Actions = useMemo(() => {
    return actions
      ? (_key: SnackbarKey) => (
          <>
            <button
              className={styles["snackbarPrimaryButton"]}
              onClick={actions.primary.onClick}
            >
              <Localized id={actions.primary.fluentId}>
                {actions.primary.label}
              </Localized>
            </button>
          </>
        )
      : null
  }, [actions])

  const localizedMessage = useMemo(() => {
    const fallback = message?.toString() ?? fluentId
    return l10n.getString(fluentId, undefined, fallback)
  }, [message, fluentId, l10n])

  return (
    <NotistackSnackbar
      ref={ref}
      action={Actions}
      message={localizedMessage}
      {...other}
    />
  )
})

SnackbarComponent.displayName = "SnackbarComponent"
