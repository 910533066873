import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"

export function reviewSchemaSchema(): NodeSchema<
  | "review_schema"
  | "review_schema_title"
  | "review_schema_description"
  | "review_item"
  | "review_item_title"
  | "review_item_content"
> {
  return {
    review_schema: {
      inline: false,
      attrs: {
        ...CommonBlockAttrs.spec,
        listStyle: { default: "decimal" },
      },
      group: "block",
      content: "review_schema_title? review_schema_description? review_item*",
      allowGapCursor: false,
      atom: true,
      // This should be `false` to allow list item lifts
      // https://prosemirror.net/docs/ref/#transform.liftTarget
      isolating: false,
      defining: true,
      parseDOM: [
        {
          tag: 'ol[data-type="review-schema"]',
          priority: 100,
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return [
          "ol",
          {
            ...CommonBlockAttrs.serialize(node),
            "data-type": "review-schema",
            "data-list-style": node.attrs["listStyle"],
          },
          0,
        ]
      },
    },

    review_schema_title: {
      content: "inline*",
      isolating: true,
      defining: true,
      selectable: false,
      attrs: { guid: { default: "" } },
      parseDOM: [{ tag: 'p[data-type="review-schema-title"]', priority: 100 }],
      toDOM(node) {
        return [
          "p",
          {
            "data-guid": node.attrs["guid"],
            "data-type": "review-schema-title",
          },
          0,
        ]
      },
    },

    review_schema_description: {
      content: "(paragraph|bullet_list|ordered_list)+",
      attrs: { guid: { default: "" } },
      isolating: true,
      defining: true,
      selectable: false,
      parseDOM: [
        { tag: 'div[data-type="review-schema-description"]', priority: 100 },
      ],
      toDOM(node) {
        return [
          "div",
          {
            "data-guid": node.attrs["guid"],
            "data-type": "review-schema-description",
          },
          0,
        ]
      },
    },

    review_item: {
      content: "review_item_title review_item_content review_schema*",
      inline: false,
      attrs: { guid: { default: "" } },
      defining: true,
      isolating: false,
      parseDOM: [{ tag: 'li[data-type="review-item"]', priority: 100 }],
      toDOM(node) {
        return [
          "li",
          {
            "data-guid": node.attrs["guid"],
            "data-type": "review-item",
          },
          0,
        ]
      },
    },

    review_item_title: {
      content: "inline*",
      isolating: true,
      defining: true,
      attrs: { guid: { default: "" } },
      parseDOM: [{ tag: 'p[data-type="review-item-title"]', priority: 100 }],
      toDOM(node) {
        return [
          "p",
          {
            "data-guid": node.attrs["guid"],
            "data-type": "review-item-title",
          },
          0,
        ]
      },
    },

    review_item_content: {
      content: "(paragraph|bullet_list)+",
      attrs: { guid: { default: "" } },
      isolating: true,
      defining: true,
      parseDOM: [
        { tag: 'div[data-type="review-item-content"]', priority: 100 },
      ],
      toDOM(node) {
        return [
          "div",
          {
            "data-guid": node.attrs["guid"],
            "data-type": "review-item-content",
          },
          0,
        ]
      },
    },
  }
}
