import { type MarkSpec, type Node, type NodeSpec } from "prosemirror-model"

import { validateMediaURL } from "@/utils/url"

export type NodeSchema<T extends string> = Record<T, NodeSpec>
export type MarkSchema<T extends string> = Record<T, MarkSpec>

/**
 * ATTRIBUTES
 */

/** */

export const CommonBlockAttrs = {
  spec: {
    guid: { default: "" },
    marginNumber: { default: undefined },
  },
  serialize: (node: Node) => ({
    ...(node.attrs.guid
      ? { id: node.attrs.guid, "data-guid": node.attrs.guid }
      : {}),
    ...(node.attrs.marginNumber
      ? { "data-margin-number": node.attrs.marginNumber }
      : {}),
  }),
  parse: (dom: HTMLElement | string) =>
    dom instanceof HTMLElement
      ? {
          guid: dom.dataset.guid,
          marginNumber: dom.dataset.marginNumber ?? undefined,
        }
      : {},
}

/**
 * NODES
 */

/** */

export function textSchema(): NodeSchema<"text"> {
  return {
    text: {
      group: "inline",
    },
  }
}

/**
 * @dependencies inline
 */

export function paragraphSchema(): NodeSchema<"paragraph"> {
  return {
    paragraph: {
      content: "inline*",
      group: "block",
      attrs: CommonBlockAttrs.spec,
      parseDOM: [{ tag: "p", getAttrs: CommonBlockAttrs.parse }],
      toDOM(node) {
        return ["p", CommonBlockAttrs.serialize(node), 0]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list | heading
 */

export function blockquoteSchema(): NodeSchema<"blockquote"> {
  return {
    blockquote: {
      content: "(paragraph | bullet_list | ordered_list | heading )+",
      group: "block",
      defining: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "blockquote",
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return ["blockquote", CommonBlockAttrs.serialize(node), 0]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list | heading
 */

export function calloutSchema(): NodeSchema<"callout"> {
  return {
    callout: {
      content: "(paragraph | bullet_list | ordered_list | heading )+",
      group: "block",
      defining: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "div.callout",
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return [
          "div",
          { ...CommonBlockAttrs.serialize(node), class: "callout" },
          0,
        ]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list | heading
 */

export function detailsSchema(): NodeSchema<"details"> {
  return {
    details: {
      content: "(paragraph | bullet_list | ordered_list | heading )+",
      group: "block",
      defining: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "div.details",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            class: "details",
          }),
        },
      ],
      toDOM(node) {
        return [
          "div",
          { ...CommonBlockAttrs.serialize(node), class: "details" },
          0,
        ]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list | heading
 */

export function boxedSchema(): NodeSchema<"boxed"> {
  return {
    boxed: {
      content: "(paragraph | bullet_list | ordered_list | heading )+",
      group: "block",
      defining: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "div.boxed",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            class: "boxed",
          }),
        },
      ],
      toDOM(node) {
        return [
          "div",
          { ...CommonBlockAttrs.serialize(node), class: "boxed" },
          0,
        ]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list | heading
 */

export function instructionSchema(): NodeSchema<"instruction"> {
  return {
    instruction: {
      content: "(paragraph | bullet_list | ordered_list )+",
      group: "block",
      defining: true,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "div.instruction",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            class: "instruction",
          }),
        },
      ],
      toDOM(node) {
        return [
          "div",
          { ...CommonBlockAttrs.serialize(node), class: "instruction" },
          0,
        ]
      },
    },
  }
}

/**
 * @dependencies inline
 */

export function headingSchema(): NodeSchema<"heading"> {
  return {
    heading: {
      attrs: { ...CommonBlockAttrs.spec, level: { default: 1 } },
      content: "inline*",
      group: "block",
      defining: true,
      parseDOM: [
        {
          tag: "h1",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 1,
          }),
        },
        {
          tag: "h2",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 2,
          }),
        },
        {
          tag: "h3",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 3,
          }),
        },
        {
          tag: "h4",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 4,
          }),
        },
        {
          tag: "h5",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 5,
          }),
        },
        {
          tag: "h6",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 6,
          }),
        },
        {
          tag: "p.faux-heading-7",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 7,
          }),
        },
        {
          tag: "p.faux-heading-8",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 8,
          }),
        },
        {
          tag: "p.faux-heading-9",
          getAttrs: (dom) => ({
            ...CommonBlockAttrs.parse?.(dom),
            level: 9,
          }),
        },
      ],
      toDOM(node) {
        if (node.attrs.level < 7) {
          return ["h" + node.attrs.level, CommonBlockAttrs.serialize(node), 0]
        } else {
          return [
            "p",
            {
              ...CommonBlockAttrs.serialize(node),
              class: `faux-heading-${node.attrs.level}`,
            },
            0,
          ]
        }
      },
    },
  }
}

/**
 * @dependencies paragraph
 */

export function listSchema(): NodeSchema<
  "ordered_list" | "bullet_list" | "list_item"
> {
  return {
    ordered_list: {
      group: "block",
      content: "list_item+",
      attrs: { ...CommonBlockAttrs.spec, order: { default: 1 } },
      parseDOM: [
        {
          tag: "ol",
          getAttrs(dom) {
            if (!(dom instanceof HTMLElement)) return {}
            return {
              ...CommonBlockAttrs.parse?.(dom),
              order: dom.hasAttribute("start")
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  +dom.getAttribute("start")!
                : 1,
            }
          },
        },
      ],
      toDOM(node) {
        return node.attrs.order == 1
          ? ["ol", CommonBlockAttrs.serialize(node), 0]
          : [
              "ol",
              { ...CommonBlockAttrs.serialize(node), start: node.attrs.order },
              0,
            ]
      },
    },

    bullet_list: {
      group: "block",
      attrs: CommonBlockAttrs.spec,
      content: "list_item+",
      parseDOM: [{ tag: "ul", getAttrs: CommonBlockAttrs.parse }],
      toDOM(node) {
        return ["ul", CommonBlockAttrs.serialize(node), 0]
      },
    },

    list_item: {
      content: "paragraph+ (ordered_list | bullet_list)*",
      parseDOM: [{ tag: "li" }],
      toDOM(_node) {
        return ["li", {}, 0]
      },
      defining: true,
    },
  }
}

export function imageSchema(): NodeSchema<"image"> {
  return {
    image: {
      inline: false,
      attrs: {
        ...CommonBlockAttrs.spec,
        src: {},
        alt: { default: null },
        title: { default: null },
      },
      group: "block",
      parseDOM: [
        {
          tag: "img[src]",
          getAttrs(dom) {
            if (!(dom instanceof HTMLElement)) return {}
            return {
              ...CommonBlockAttrs.parse?.(dom),
              src: dom.getAttribute("src"),
              title: dom.getAttribute("title"),
              alt: dom.getAttribute("alt"),
            }
          },
        },
      ],
      toDOM(node) {
        const { src, alt, title } = node.attrs
        const mediaURL = validateMediaURL(src)
        return mediaURL
          ? [
              "div",
              {
                ...CommonBlockAttrs.serialize(node),
                "data-type": "image",
              },
              ["img", { src: mediaURL, alt, title }],
            ]
          : ["img"]
      },
    },
  }
}

export function embedSchema(): NodeSchema<"embed"> {
  return {
    embed: {
      inline: false,
      attrs: {
        ...CommonBlockAttrs.spec,
        src: {},
        frameborder: { default: 0 },
        allow: {
          default:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        },
        allowfullscreen: { default: true },
      },
      group: "block",
      parseDOM: [
        {
          tag: "iframe",
          getAttrs(dom) {
            if (!(dom instanceof HTMLElement)) return {}
            return {
              ...CommonBlockAttrs.parse?.(dom),
              src: dom.getAttribute("src"),
            }
          },
        },
      ],
      toDOM(node) {
        const { src, frameborder, allow, allowfullscreen } = node.attrs
        return [
          "iframe",
          {
            ...CommonBlockAttrs.serialize(node),
            src,
            frameborder,
            allow,
            allowfullscreen,
          },
        ]
      },
    },
  }
}

/**
 * @dependencies paragraph | bullet_list | ordered_list
 */

export function definitionSchema(): NodeSchema<
  "definition" | "definition_title" | "definition_content"
> {
  return {
    definition: {
      content: "definition_title definition_content",
      group: "block",
      defining: true,
      isolating: true,
      allowGapCursor: false,
      attrs: CommonBlockAttrs.spec,
      parseDOM: [
        {
          tag: "div[data-type='definition']",
          getAttrs: CommonBlockAttrs.parse,
        },
      ],
      toDOM(node) {
        return [
          "div",
          {
            ...CommonBlockAttrs.serialize(node),
            "data-type": "definition",
            class: "definition",
          },
          0,
        ]
      },
    },

    definition_title: {
      content: "text*",
      inline: false,
      selectable: false,
      isolating: true,
      marks: "",
      parseDOM: [{ tag: "div[data-type='definition-title']" }],
      toDOM() {
        return [
          "div",
          {
            "data-type": "definition-title",
            class: "definition-title",
          },
          0,
        ]
      },
    },

    definition_content: {
      content: "(paragraph|bullet_list|ordered_list)+",
      inline: false,
      selectable: false,
      allowGapCursor: false,
      isolating: true,
      parseDOM: [{ tag: "div[data-type='definition-content']" }],
      toDOM() {
        return [
          "div",
          {
            "data-type": "definition-content",
            class: "definition-content",
          },
          0,
        ]
      },
    },
  }
}

/**
 * MARKS
 */

/** */

export function formattingSchema(): MarkSchema<"em" | "strong" | "underline"> {
  return {
    // MarkSpec An emphasis mark. Rendered as an `<em>` element.
    // Has parse rules that also match `<i>` and `font-style: italic`.
    em: {
      parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
      toDOM() {
        return ["em", 0]
      },
    },

    // MarkSpec A strong mark. Rendered as `<strong>`, parse rules
    // also match `<b>` and `font-weight: bold`.
    strong: {
      parseDOM: [
        { tag: "strong" },
        // This works around a Google Docs misbehavior where
        // pasted content will be inexplicably wrapped in `<b>`
        // tags with a font-weight normal.
        {
          tag: "b",
          getAttrs: (node) =>
            node instanceof HTMLElement &&
            node.style.fontWeight != "normal" &&
            null,
        },
        {
          style: "font-weight",
          getAttrs: (value) =>
            /^(bold(er)?|[5-9]\d{2,})$/.test(
              typeof value === "string" ? value : ""
            ) && null,
        },
      ],
      toDOM() {
        return ["strong", 0]
      },
    },

    underline: {
      parseDOM: [{ tag: "u" }],
      toDOM() {
        return ["span", { style: { textDecoration: "underline" } }, 0]
      },
    },
  }
}

export function linkSchema(): MarkSchema<"link"> {
  return {
    link: {
      attrs: {
        href: {},
        title: { default: null },
        autoLinked: { default: false },
        /** Deprecated */
        resourceId: { default: null },
        /** Deprecated */
        resourceType: { default: null },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: "a[href]",
          getAttrs(dom) {
            if (!(dom instanceof HTMLElement)) return {}
            return {
              href: dom.getAttribute("href"),
              title: dom.getAttribute("title"),
            }
          },
        },
      ],
      toDOM(node) {
        const { href, title, resourceId, resourceType } = node.attrs
        return [
          "a",
          {
            href,
            title,
            "data-resource-id": resourceId,
            "data-resource-type": resourceType,
          },
          0,
        ]
      },
    },
  }
}

export function importWarningSchema(): NodeSchema<"import_warning"> {
  return {
    import_warning: {
      inline: true,
      group: "inline",
      attrs: { removedType: { default: "" }, guid: { default: "" } },
      toDOM(node: Node) {
        return [
          "span",
          {
            "data-type": "import-warning",
            "data-removed-type": node.attrs.removedType,
          },
        ]
      },
      parseDOM: [{ tag: "span.import-warning" }],
    } as NodeSpec,
  }
}
