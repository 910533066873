import { type Node, Schema } from "prosemirror-model"

import {
  blockquoteSchema,
  formattingSchema,
  headingSchema,
  linkSchema,
  listSchema,
  paragraphSchema,
  textSchema,
} from "@/schemas/base-schema"

export const flashcardSchema = new Schema({
  nodes: {
    doc: {
      content: "front back",
    },
    front: {
      content: "block+",
      toDOM: () => ["div", { class: "flashcard-front" }, 0],
      parseDOM: [{ tag: "div.flashcard-front" }],
    },
    back: {
      content: "block+",
      toDOM: () => ["div", { class: "flashcard-back" }, 0],
      parseDOM: [{ tag: "div.flashcard-back" }],
    },
    ...paragraphSchema(),
    ...headingSchema(),
    ...listSchema(),
    ...textSchema(),
    ...blockquoteSchema(),
  },
  marks: {
    ...linkSchema(),
    ...formattingSchema(),
  },
})

export const getFrontContentNode = (flashcard?: Node) =>
  flashcard?.content.child(0)

export const getBackContentNode = (flashcard?: Node) =>
  flashcard?.content.child(1)

export const isFlashcardDocStructure = (doc?: Node) => {
  // It's not a flashcard if it doesn't have at least two children
  if (!doc || doc.content.childCount < 2) return false

  const frontContent = doc?.content.child(0).type.name === "front"
  const backContent = doc?.content.child(1).type.name === "back"

  return frontContent && backContent
}

export const isEmptyFlashcardSide = (
  flashcard?: Node,
  side?: "front" | "back"
) => {
  if (!flashcard) return false

  const content =
    side === "front"
      ? getFrontContentNode(flashcard)
      : getBackContentNode(flashcard)

  return (
    !content || content?.childCount === 0 || content.child(0)?.childCount === 0
  )
}
