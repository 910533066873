import { createSlice } from "@reduxjs/toolkit"

import { apiSlice } from "./api"

import type { UUID } from "@/store/UUID"
import type { Chapter } from "@/types/api"

type ChaptersState = Record<UUID, Chapter>

/**
 * The documents slice contains all the state logic for the editor.
 * While the api slice fetches the data, the `documents` slice works
 * with the parsed editor state.
 */

export const chaptersSlice = createSlice({
  name: "chapters",
  initialState: {
    chapters: {},
    revisions: {},
    footnotes: {},
  } as ChaptersState,
  reducers: {},
  extraReducers: (builder) => {
    // To access the chapter data inside `documents`
    builder.addMatcher(
      apiSlice.endpoints.getChapter.matchFulfilled,
      (state, { payload }) => {
        if (!state[payload.id]) {
          state[payload.id] = payload
        }
      }
    )
  },
})
