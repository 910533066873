import { QueryStatus } from "@reduxjs/toolkit/dist/query"
import { Mapping } from "prosemirror-transform"

import { transactionDispatched } from "./transactionDispatched"

import {
  type ParsedMetaPayload,
  referenceParsingPluginKey,
} from "@/plugins/referenceParsing/referenceParsingPlugin"
import { chapterSchema } from "@/schemas/chapter/schema"
import type { UUID } from "@/store/UUID"
import {
  getAtomState,
  getRevisionEditorState,
} from "@/store/selectors/documentsSelectors"
import { apiSlice } from "@/store/slices/api"
import { createAppThunk } from "@/store/store"
import { maybeMap, rangeHasManualLink } from "@/utils/prosemirror"

/**
 * Handles backend identification of auto-detected references.
 *
 * Whenever the referenceParsingPlugin detects a reference in the text,
 * this thunk dispatches a request to the backend to produce links for
 * those references. For each link produced, it uses the thunk to map
 * the return positions through the plugin's mapping for the relevant
 * reference, and, as long as the text at the mapped range has not
 * changed since the request was sent, adds a link mark to the range.
 */
export const referenceParsed = createAppThunk(
  "documents/referenceParsedThunk",
  async (
    payload:
      | {
          documentId: UUID
          revisionId: UUID
          parsed: ParsedMetaPayload
          fromAtom?: false
        }
      | {
          documentId: UUID
          revisionId: UUID
          parsed: ParsedMetaPayload
          fromAtom: true
          atomPos: number
        },
    { dispatch, getState }
  ) => {
    for (const reference of payload.parsed) {
      dispatch(
        apiSlice.endpoints.getReferenceLinks.initiate({
          text: reference.text,
          referenceType: reference.referenceType,
        })
      ).then(({ status, data }) => {
        if (status !== QueryStatus.fulfilled || !data) return

        const editorState = payload.fromAtom
          ? getAtomState(getState(), payload.revisionId)
          : getRevisionEditorState(getState(), payload.revisionId)
        if (!editorState) return

        const mapping =
          referenceParsingPluginKey.getState(editorState)?.[reference.id] ??
          new Mapping()

        const tr = editorState.tr

        for (const link of data.links) {
          const start = maybeMap(mapping, reference.start + link.start)
          const end = maybeMap(mapping, reference.start + link.end, -1)

          const currentText = editorState.doc.textBetween(start, end)

          // If the text has changed since we initiated the request,
          // then bail
          if (currentText !== reference.text.slice(link.start, link.end))
            continue

          const linkMark = chapterSchema.marks.link.create({
            ...link.resource,
            autoLinked: true,
          })
          const existingLink = rangeHasManualLink(tr.doc, start, end)
          if (!existingLink) {
            tr.addMark(start, end, linkMark)
          }
        }

        tr.setMeta(referenceParsingPluginKey, {
          type: "cleared",
          payload: [reference.id],
        })

        dispatch(
          transactionDispatched({
            documentType: "chapter",
            ...payload,
            transaction: tr,
          })
        )
      })
    }
  }
)
