/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type EditorState, TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

export function insertTable(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (
    !(
      state.schema.nodes["table"] &&
      state.schema.nodes["table_row"] &&
      state.schema.nodes["table_cell"]
    )
  ) {
    return false
  }

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["table"]
  )

  if (validInsertPoint === null) return false

  if (dispatch) {
    const table = createTable(state)
    dispatch(state.tr.insert(validInsertPoint, table))
  }

  return true
}

export function createTable(state: EditorState) {
  return state.schema.nodes["table"]!.create({}, [
    state.schema.nodes["table_row"]!.create({}, [
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
    ]),
    state.schema.nodes["table_row"]!.create({}, [
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
    ]),
    state.schema.nodes["table_row"]!.create({}, [
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
      state.schema.nodes["table_cell"]!.createAndFill()!,
    ]),
  ])
}

export function moveCursorToNextTableCell(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection

  // If the block we are in is not a table, do nothing
  const blockNode = $from.node(1)
  if (!blockNode || blockNode.type !== state.schema.nodes["table"]) {
    return false
  }

  if (dispatch) {
    let gap = 3
    const nextPosition = $from.end(3) + gap
    const nodeAtNextPosition = $from.doc.resolve(nextPosition).node(3)
    if (
      !nodeAtNextPosition ||
      nodeAtNextPosition.type !== state.schema.nodes["table_cell"]
    ) {
      gap = 5
    }

    dispatch(
      state.tr.setSelection(TextSelection.create(state.doc, $from.end(3) + gap))
    )
    return true
  }
  return false
}

export function moveCursorToPreviousTableCell(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection

  // If the block we are in is not a table, do nothing
  const blockNode = $from.node(1)
  if (!blockNode || blockNode.type !== state.schema.nodes["table"]) {
    return false
  }

  if (dispatch) {
    let gap = 3
    const previousPosition = $from.start(3) - gap
    const nodeAtPreviousPosition = $from.doc.resolve(previousPosition).node(3)
    if (
      !nodeAtPreviousPosition ||
      nodeAtPreviousPosition.type !== state.schema.nodes["table_cell"]
    ) {
      gap = 4
    }
    dispatch(
      state.tr.setSelection(
        TextSelection.create(state.doc, $from.start(3) - gap)
      )
    )
    return true
  }

  return false
}
