import {
  type AsyncThunk,
  type AsyncThunkOptions,
  type AsyncThunkPayloadCreator,
  combineReducers,
  configureStore,
  createAsyncThunk,
  isPlain,
} from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { Node, Schema } from "prosemirror-model"
import { EditorState, Selection, Transaction } from "prosemirror-state"
import { Step } from "prosemirror-transform"

import { snackbarsReducer } from "./reducers/snackbars.ts"
import { apiSlice } from "./slices/api.ts"
import { chaptersSlice } from "./slices/chapters.ts"
import { citationsSlice } from "./slices/citations.ts"
import { dialogsSlice } from "./slices/dialogs"
import { flashcardsLearningSlice } from "./slices/flashcardLearning.ts"
import { flashcardsSlice } from "./slices/flashcards.ts"
import { notesSlice } from "./slices/notes.ts"
import { panelsSlice } from "./slices/panels.ts"
import { revisionsSlice } from "./slices/revisions.ts"
import { threadsSlice } from "./slices/threads.ts"
import { viewerSlice } from "./slices/viewer.ts"

import { sentryReduxEnhancer } from "@/utils/sentry.ts"

export const {
  atomNodeActivated,
  toggleFootnoteVisibility,
  toggleAllFootnoteVisibility,
  setFootnoteNumbers,
} = revisionsSlice.actions

export const { dialogClosed, dialogOpened } = dialogsSlice.actions

export const { loadInitialPanels, panelOpened, panelClosed } =
  panelsSlice.actions

export const {
  threadOpened,
  threadClosed,
  toggleThreadsVisibility,
  threadRemoved,
} = threadsSlice.actions

export const { flashcardEditClicked, flashcardSaved } = flashcardsSlice.actions

const reducer = combineReducers({
  /** SLICE REDUCERS */
  [apiSlice.reducerPath]: apiSlice.reducer,
  chapters: chaptersSlice.reducer,
  flashcards: flashcardsSlice.reducer,
  flashcardsLearning: flashcardsLearningSlice.reducer,
  revisions: revisionsSlice.reducer,
  dialogs: dialogsSlice.reducer,
  panels: panelsSlice.reducer,
  threads: threadsSlice.reducer,
  viewer: viewerSlice.reducer,
  notes: notesSlice.reducer,
  citations: citationsSlice.reducer,
  /** SLICE-LESS REDUCERS */
  snackbars: snackbarsReducer,
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (value: unknown) =>
          value instanceof EditorState ||
          value instanceof Transaction ||
          value instanceof Selection ||
          value instanceof Schema ||
          value instanceof Node ||
          value instanceof Step ||
          isPlain(value),
        getEntries: (value: Record<string, unknown>) =>
          value instanceof EditorState ||
          value instanceof Transaction ||
          value instanceof Selection ||
          value instanceof Schema ||
          value instanceof Node ||
          value instanceof Step
            ? ([] as [string, unknown][])
            : Object.entries(value),
      },
    }).concat(apiSlice.middleware),
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryReduxEnhancer),
})

// required for `refetchOnFocus` and `refetchOnReconnect`
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunkConfig = { state: RootState }

export function createAppThunk<Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AppThunkConfig>,
  options?: AsyncThunkOptions<ThunkArg, AppThunkConfig>
): AsyncThunk<Returned, ThunkArg, AppThunkConfig> {
  return createAsyncThunk(typePrefix, payloadCreator, options)
}
