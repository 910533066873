import {
  getVersion,
  receiveTransaction,
  sendableSteps,
} from "prosemirror-collab"

import type { UUID } from "@/store/UUID"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { apiSlice } from "@/store/slices/api"
import { revisionsSlice } from "@/store/slices/revisions"
import { stepWriteMutex } from "@/store/stepWriteMutex"
import { createAppThunk } from "@/store/store"
import { padLogger } from "@/utils/debug"
import { sendErrorToSentry } from "@/utils/sentry"

export const transactionSendTriggered = createAppThunk(
  "documents/transactionSendTriggered",
  async (
    {
      documentType,
      documentId,
      revisionId,
    }: {
      documentType: "chapter" | "flashcard" | "note"
      documentId: UUID
      revisionId: UUID
    },
    { getState, dispatch }
  ) => {
    try {
      await stepWriteMutex.wait()

      const editorState = getRevisionEditorState(getState(), revisionId)
      if (!editorState) return

      const sendable = sendableSteps(editorState)
      if (!sendable) return

      const version = getVersion(editorState)

      padLogger("sending steps", { version, unconfirmed: sendable.steps })
      await dispatch(
        apiSlice.endpoints.createSteps.initiate({
          documentType,
          documentId,
          version,
          steps: sendable.steps,
          clientId: sendable.clientID as UUID,
        })
      ).unwrap()

      const latestEditorState = getRevisionEditorState(getState(), revisionId)
      if (!latestEditorState) return

      // If we successfully write our steps, we can "receive" them locally
      // to speed up the persistence flow.
      const transaction = receiveTransaction(
        latestEditorState,
        sendable.steps,
        sendable.steps.map(() => sendable.clientID)
      )

      dispatch(
        revisionsSlice.actions.remoteTransactionReceived({
          revisionId,
          transaction,
        })
      )
    } catch (e) {
      // We don't need to report step write conflicts to Sentry; they're expected
      // during collaboration.
      if (!(typeof e === "object" && e && "status" in e && e.status === 409)) {
        sendErrorToSentry(`Could not create steps on the server`, e)
      }
    } finally {
      stepWriteMutex.release()
    }
  }
)
