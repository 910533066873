import { SnackbarProvider as NotistackProvider } from "notistack"

import { SnackbarComponent } from "./Snackbar"

type SnackbarProviderProps = {
  children: React.ReactNode
}

export function SnackbarProvider(props: SnackbarProviderProps) {
  return (
    <NotistackProvider
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      Components={{
        default: SnackbarComponent,
        error: SnackbarComponent,
        info: SnackbarComponent,
        success: SnackbarComponent,
        warning: SnackbarComponent,
      }}
    >
      {props.children}
    </NotistackProvider>
  )
}
