import { FluentBundle, FluentResource } from "@fluent/bundle"
import { negotiateLanguages } from "@fluent/langneg"
import {
  browserTracingIntegration,
  captureException,
  createReduxEnhancer,
  feedbackIntegration,
  init,
  replayIntegration,
  setUser,
} from "@sentry/react"

import { padLogger } from "./debug"

export function initSentry({ username }: { username?: string } = {}) {
  if (!import.meta.env.VITE_SENTRY_DSN) return

  const environment = getSentryEnvironment()
  if (environment === "development") return

  init({
    environment,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [browserTracingIntegration(), replayIntegration(), feedback],
    tracesSampleRate: environment === "staging" ? 0.5 : 0.1,

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications/,
    ],
  })

  if (username) setUser({ username })

  // Attach the feedback button to the feedback integration to trigger the feedback form
  const feedbackButton = document.querySelector("#feedback-button")
  if (feedbackButton) feedback.attachTo(feedbackButton, {})
}

export const sentryReduxEnhancer = createReduxEnhancer({
  // Avoid sending big payloads to Sentry (e.g. EditorStates, etc.)
  attachReduxState: false,
})

function getSentryEnvironment(): "development" | "staging" | "production" {
  return import.meta.env.VITE_SENTRY_ENVIRONMENT || "development"
}

const sentryBundle = getSentryFluentBundle()
const feedback = feedbackIntegration({
  autoInject: false,
  showBranding: false,
  colorScheme: "light",
  submitButtonLabel: sentryBundle.formatPattern(
    sentryBundle.getMessage("feedback-submit")?.value || "Submit"
  ),
  formTitle: sentryBundle.formatPattern(
    sentryBundle.getMessage("form-title")?.value || "Feedback"
  ),
  messagePlaceholder: sentryBundle.formatPattern(
    sentryBundle.getMessage("message-placeholder")?.value ||
      "What do you want to tell us?"
  ),
  messageLabel: sentryBundle.formatPattern(
    sentryBundle.getMessage("message-label")?.value || "Feedback"
  ),
  isRequiredLabel: sentryBundle.formatPattern(
    sentryBundle.getMessage("required-label")?.value || "(Required)"
  ),
})

export function sendErrorToSentry(message?: string, error?: unknown) {
  padLogger(message, error)

  const context = { tags: { message } }

  try {
    if (error instanceof Error) {
      captureException(error, context)
    } else {
      try {
        throw new Error(getErrorMessage(error))
      } catch (e) {
        captureException(e, context)
      }
    }
  } catch (e) {
    captureException("Could not send error to Sentry: " + (e as Error)?.message)
  }
}

function getErrorMessage(error?: unknown): string {
  if (isObjectWithMessage(error)) return error.message
  if (isRTKError(error)) return error.data.content.join("\n")
  return typeof error === "string" ? error : ""
}

type AnyObject = Record<string, unknown>
const isObjectWithMessage = (error: unknown): error is { message: string } =>
  !!error &&
  typeof error === "object" &&
  typeof (error as AnyObject)["message"] === "string"
const isRTKError = (error: unknown): error is { data: { content: string[] } } =>
  !!error &&
  typeof error === "object" &&
  typeof (error as AnyObject)["data"] === "object" &&
  Array.isArray((error as Record<string, AnyObject>)["data"]?.["content"])

/**
 * This function is used to create a FluentBundle with the translations for the
 * Sentry feedback form. We do this separately from the AppLocalizationProvider
 * because this is temporary until the top menu is refactored to use React and
 * the translations for Sentry are very few.
 */
function getSentryFluentBundle() {
  const resources = {
    de: new FluentResource(`
form-title = Feedback senden
feedback-submit = Senden
message-placeholder = Was möchtest du uns mitteilen?
message-label = Feedback
required-label = (Erforderlich)
`),
    en: new FluentResource(`
form-title = Send Feedback
feedback-submit = Submit
message-placeholder = What do you want to tell us?
message-label = Feedback
required-label = (Required)
`),
  }

  const availableLocales = ["de", "en"]
  const bundle = new FluentBundle(availableLocales)
  const negotiationResult = negotiateLanguages(
    navigator.languages,
    availableLocales
  )
  const locale = negotiationResult[0] || "en"
  bundle.addResource(resources[locale as "de" | "en"])
  return bundle
}
