import type { UUID } from "@/store/UUID"
import type { RootState } from "@/store/store"

export function getFlashcardId(
  state: RootState,
  revisionId: UUID,
  highlightId: UUID
) {
  return getFlashcardState(state, revisionId, highlightId)?.flashcardId
}

export function getFlashcardState(
  state: RootState,
  revisionId: UUID,
  highlightId: UUID
) {
  return state.flashcards[revisionId]?.[highlightId] ?? null
}
