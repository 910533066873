/* eslint-disable @typescript-eslint/no-explicit-any */

export function debounce<F extends (...params: any[]) => void>(
  fn?: F,
  delay?: number,
  options?: {
    /**
     * Run the debounced function immediately if no execution
     * is already pending.
     *
     * Defaults to false.
     */
    leading?: boolean
    /**
     * Run the debounced function after `delay`, if it wasn't
     * run when the function was called.
     *
     * Defaults to true.
     */
    trailing?: boolean
    /**
     * If there's a pending execution, return immediately rather
     * than rescheduling. Useful when caller calls frequently enough
     * to overwhelm event loop scheduling.
     *
     * Defaults to false.
     */
    takeLeading?: boolean
  }
) {
  let timeoutID: number | null = null
  let isLeadingInvoked = false

  const takeLeading = options?.takeLeading ?? false
  const leading = options?.leading ?? false
  const trailing = options?.trailing ?? true

  return function (this: any, ...args: any[]) {
    if (timeoutID) {
      if (takeLeading) return
      clearTimeout(timeoutID)
    }

    if (leading && !timeoutID) {
      fn?.apply(this, args)
      isLeadingInvoked = true
    } else {
      isLeadingInvoked = false
    }

    timeoutID = window.setTimeout(() => {
      if (trailing && !isLeadingInvoked) {
        fn?.apply(this, args)
      }

      timeoutID = null
    }, delay || 100)
  } as F
}
