import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import {
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { Step } from "prosemirror-transform"

import { flashcardSchema } from "@/schemas/flashcard/schema.ts"
import type { UUID } from "@/store/UUID"
import { threadRemoved } from "@/store/store"
import {
  type Chapter,
  type ChapterResponse,
  type ChapterRevisionResponse,
  type CourtDecision,
  type CourtDecisionResourceSearchResult,
  type CourtDecisionResourceSearchResultResponse,
  type CourtDecisionResponse,
  type DocumentContainerPermissions,
  type DocumentRevision,
  type Flashcard,
  type FlashcardDeck,
  type FlashcardDeckResponse,
  FlashcardReportLevel,
  type FlashcardResponse,
  type FlashcardText,
  type Highlight,
  type HighlightResponse,
  type ImportDocumentDetail,
  type ImportDocumentDetailResponse,
  type ImportDocumentSplitDetail,
  type ImportFlowDetail,
  type ImportFlowDetailResponse,
  type InstitutionInfo,
  type InstitutionInfoResponse,
  type InstitutionUser,
  type InstitutionUserResponse,
  type InstitutionUserTypeahead,
  type InstitutionUserTypeaheadResponse,
  type InstitutionUsers,
  type InstitutionUsersResponse,
  type Knowledge,
  type KnowledgeResponse,
  type LinkableResourcesResponse,
  type MagicQuiz,
  type Message,
  type MessageResponse,
  type Note,
  type NoteResponse,
  type Notebook,
  type NotebookResponse,
  type PermissionEntity,
  type PermissionEntitySearchResult,
  PermissionEntityType,
  type ReferenceLinksResponse,
  type ReferenceResourceSearchResult,
  type ReferenceResourceSearchResultResponse,
  type ResourceSearchResults,
  type ResourceSearchResultsResponse,
  type RevisionList,
  type SectionResourceSearchResult,
  type SectionResourceSearchResultResponse,
  type StatuteSection,
  type StatuteSectionResponse,
  type Steps,
  type StepsResponse,
  type StudySession,
  type StudySessionResponse,
  type UploadFileResponse,
  type UserSearchResult,
  type UserSearchResultResponse,
  type UserSettings,
  type UserSettingsEmail,
  type UserSettingsInstitution,
  type UserSettingsInstitutionResponse,
  type UserSettingsResponse,
  type VerifyUsersInInstitution,
  type VerifyUsersInInstitutionResponse,
  type VideoSearchResult,
  type VideoSearchResultItem,
  type VideoSearchResultResponse,
} from "@/types/api"
import type { JSONContent } from "@/types/utils"
import { getCsrfToken } from "@/utils/cookies"
import { sendErrorToSentry } from "@/utils/sentry"

/**
 * A slice for managing the data, received from the pad API.
 * https://redux-toolkit.js.org/rtk-query/api/createApi
 */

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: [
    "Resources",
    "Messages",
    "Chapters",
    "Revisions",
    "Flashcards",
    "FlashcardDecks",
    "FlashcardDeckPermissions",
    "StudySession",
    "Highlights",
    "ImportFlow",
    "Institution",
    "InstitutionUsers",
    "UserProfile",
    "Notebooks",
    "NotebookPermissions",
    "Notes",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers) => {
      const csrfToken = getCsrfToken()
      if (!csrfToken) throw new Error("Unable to find CSRF token")

      headers.set("X-CSRFToken", csrfToken)

      const contentType = headers.get("Content-Type")

      // https://github.com/reduxjs/redux-toolkit/issues/2677#issuecomment-1668290124
      if (contentType === "multipart/form-data") {
        headers.delete("Content-Type")
      } else {
        headers.set("Content-Type", "application/json")
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    /**
     * Chapters
     */
    getChapter: builder.query<Chapter, UUID>({
      query: (chapterId) => `/pad/chapters/${chapterId}`,
      providesTags: (_, __, id) => [{ type: "Chapters", id }],
      transformResponse: (response: ChapterResponse, _, id) => {
        return {
          id: id,
          title: response.title,
          description: response.description,
          permissions: { canEdit: response.permissions.is_editable },
          publishedRevisionId: response.published_revision_id,
          draftRevisionId: response.draft_revision_id,
          scriptTitle: response.script_title,
          scriptUrl: response.script_url,
        }
      },
    }),
    getChapterRevisionList: builder.query<RevisionList, UUID>({
      query: (chapterId) => `/pad/chapters/${chapterId}/revisions`,
      providesTags: (_, __, id) => [{ type: "Chapters", id }],
    }),
    getChapterRevision: builder.query<
      DocumentRevision,
      { chapterId: UUID; revisionId: UUID }
    >({
      query: ({ chapterId, revisionId }) =>
        `/pad/chapters/${chapterId}/revisions/${revisionId}`,
      providesTags: (_, __, { revisionId }) => [
        { type: "Chapters", id: revisionId },
      ],
      transformResponse: (response: ChapterRevisionResponse, _, args) => {
        return {
          id: args.revisionId,
          documentId: args.chapterId,
          name: response.name,
          status: response.status,
          snapshot: {
            id: response.snapshot.id,
            isInitial: response.snapshot.is_initial,
            version: response.snapshot.version,
            shortId: response.snapshot.short_id,
            content: response.snapshot.content,
          },
        }
      },
    }),
    createSteps: builder.mutation<
      void,
      {
        documentType: "chapter" | "flashcard" | "note"
        documentId: UUID
        clientId: UUID
        version: number
        steps: readonly Step[]
      }
    >({
      query: (payload) => ({
        url:
          payload.documentType === "chapter"
            ? `/pad/chapters/${payload.documentId}/steps`
            : payload.documentType === "flashcard"
            ? `/flashcard/${payload.documentId}/steps`
            : `/note/${payload.documentId}/steps`,
        method: "POST",
        body: payload.steps.map((step, i) => ({
          client_id: payload.clientId,
          value: step.toJSON(),
          version: payload.version + 1 + i,
        })) as { client_id: UUID; value: JSONContent; version: number }[],
      }),
    }),
    /**
     * EDITOR
     */
    searchVideos: builder.query<VideoSearchResult, string>({
      query: (query) => ({
        url: "/pad/video_search",
        params: { query },
      }),
      transformResponse: (response: VideoSearchResultResponse) => {
        return {
          success: 1,
          has_error: response.has_error,
          errors: response.errors,
          items: response.items.map((item) => toVideoSearchResult(item)),
        } as VideoSearchResult
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const error_data = error.data as VideoSearchResultResponse
        const _errors =
          (error_data?.errors?.length ?? 0) > 0
            ? error_data.errors
            : ["There was an error retrieving the videos."]
        return {
          success: 0,
          has_error: 1,
          errors: _errors,
          items: [],
        } as VideoSearchResult
      },
    }),
    uploadFile: builder.mutation<UploadFileResponse, File>({
      query: (file) => {
        const formData = new FormData()
        formData.append("file", file, file.name)
        return {
          url: "/pad/upload/",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      },
    }),
    /**
     * HIGHLIGHTS
     */
    getHighlights: builder.query<
      Highlight[],
      { chapterId: UUID; revisionId: UUID }
    >({
      query: ({ chapterId, revisionId }) =>
        `/pad/chapters/${chapterId}/highlights?revision=${revisionId}`,
      providesTags: (res, _, { chapterId, revisionId }) => [
        ...(res
          ? res.map(({ id }) => ({ type: "Highlights" as const, id }))
          : []),
        { type: "Highlights", id: revisionId },
        { type: "Highlights", id: chapterId },
      ],
      transformResponse: (response: HighlightResponse[]) => {
        return response.map((highlight) => toHighlight(highlight))
      },
    }),
    createHighlight: builder.mutation<
      Highlight,
      {
        chapterId: UUID
        revisionId: UUID
        version: number
        highlight: Pick<
          Highlight,
          "color" | "from" | "to" | "id" | "revisionId"
        >
      }
    >({
      query: ({ chapterId, revisionId, version, highlight }) => ({
        url: `/pad/chapters/${chapterId}/highlights`,
        method: "POST",
        body: {
          revision_id: revisionId,
          step_version: version,
          start: highlight.from,
          end: highlight.to,
          color: highlight.color,
        },
      }),
      invalidatesTags: (_, __, { chapterId, revisionId }) => [
        { type: "Highlights", id: revisionId },
        { type: "Highlights", id: chapterId },
      ],
      transformResponse: (response: HighlightResponse) => toHighlight(response),
      onQueryStarted: async (
        { highlight, ...args },
        { dispatch, queryFulfilled }
      ) => {
        // We update the cache immediately, so that we don't have to wait for the server.
        const optimisticUpdate = dispatch(
          apiSlice.util.updateQueryData("getHighlights", args, (draft) => {
            draft.push(highlight as Highlight)
          })
        )
        try {
          await queryFulfilled
        } catch {
          optimisticUpdate.undo()
        }
      },
    }),
    deleteHighlight: builder.mutation<
      void,
      { highlightId: UUID; revisionId: UUID; chapterId: UUID }
    >({
      query: ({ highlightId }) => ({
        method: "DELETE",
        url: `/pad/highlights/${highlightId}`,
      }),
      invalidatesTags: (_, __, { highlightId }) => [
        { type: "Highlights", id: highlightId },
        // If a highlight is deleted, associated messages are also deleted.
        { type: "Messages", id: highlightId },
      ],
      onQueryStarted: async (
        { highlightId, ...args },
        { dispatch, queryFulfilled }
      ) => {
        // We update the cache immediately, so that we don't have to wait for the server.
        const optimisticUpdate = dispatch(
          apiSlice.util.updateQueryData("getHighlights", args, (draft) => {
            const index = draft.findIndex((h) => h.id === highlightId)
            if (index !== -1) draft.splice(index, 1)
          })
        )
        dispatch(threadRemoved({ highlightId }))
        try {
          await queryFulfilled
        } catch {
          optimisticUpdate.undo()
        }
      },
    }),
    /**
     * MAGIC
     */
    generateMagicQuiz: builder.mutation<MagicQuiz, { selectedText: string }>({
      query: ({ selectedText }) => ({
        url: `/pad/magic/quiz/`,
        method: "POST",
        body: { text: selectedText },
      }),
    }),
    /**
     * MESSAGES
     */
    getMessages: builder.query<Message[], UUID>({
      query: (highlightId) => `/pad/highlights/${highlightId}/messages`,
      providesTags: (res, _, highlightId) => [
        ...(res
          ? res.map(({ id }) => ({ type: "Messages" as const, id }))
          : []),
        { type: "Messages", id: highlightId },
      ],
      transformResponse: (response: MessageResponse[]) =>
        response.map((message) => toMessage(message)),
    }),
    createMessage: builder.mutation<
      Message,
      { highlightId: UUID; content: JSONContent }
    >({
      query: ({ highlightId, content }) => ({
        url: `/pad/highlights/${highlightId}/messages`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: (_, __, { highlightId }) => [
        { type: "Messages", id: highlightId },
        // Invalidate the highlight cache, so that the highlight may get the thread decoration.
        { type: "Highlights", id: highlightId },
      ],
      transformResponse: (response: MessageResponse) => toMessage(response),
    }),
    updateMessage: builder.mutation<
      Message,
      { highlightId: UUID; messageId: UUID; content: JSONContent }
    >({
      query: ({ highlightId, messageId, content }) => ({
        url: `/pad/highlights/${highlightId}/messages/${messageId}`,
        method: "PATCH",
        body: { content },
      }),
      invalidatesTags: (_, __, arg) => [
        { type: "Messages", id: arg.messageId },
      ],
      transformResponse: (response: MessageResponse) => toMessage(response),
    }),
    deleteMessage: builder.mutation<
      void,
      { highlightId: UUID; messageId: UUID }
    >({
      query: ({ highlightId, messageId }) => ({
        url: `/pad/highlights/${highlightId}/messages/${messageId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, arg) => [
        { type: "Messages", id: arg.messageId },
        // Invalidate the highlight cache, so that the highlight may remove the thread decoration.
        { type: "Highlights", id: arg.highlightId },
      ],
    }),
    /**
     * RESOURCES
     */
    getCourtDecision: builder.query<CourtDecision, string>({
      // We need to overwrite the baseQuery URL because sections are not available under /api.
      // Overwriting can be achieved by passing the fully qualified URL, https://stackoverflow.com/a/69494764/4247704
      query: (url) => ({
        url: `${window.location.origin}${url}`,
      }),
      providesTags: (_, __, url) => [{ type: "Resources", id: url }],
      transformResponse: (response: CourtDecisionResponse, _, url) => {
        return {
          url,
          docketNumber: response.docket_number,
          ecli: response.ecli,
          title: response.title,
          date: response.date,
          decisionType: response.decision_type,
          relevantSections: response.relevant_sections,
          previousInstances: response.previous_instances,
          reasoning: response.reasoning,
          operative: response.operative,
          facts: response.facts,
          guidingPrinciples: response.guiding_principles,
          courtName: response.court_name,
        }
      },
    }),
    getStatuteSection: builder.query<
      { selectedSectionUuid: UUID; sections: StatuteSection[] },
      string
    >({
      // We need to overwrite the baseQuery URL because sections are not available under /api.
      // Overwriting can be achieved by passing the fully qualified URL, https://stackoverflow.com/a/69494764/4247704
      query: (url) => ({
        url: `${window.location.origin}${url}`,
        params: { vicinity: true },
      }),
      providesTags: (_, __, url) => [{ type: "Resources", id: url }],
      transformResponse: (response: StatuteSectionResponse, _, url) => {
        // We return a list because each section needs adjacent sections to be understood.
        return {
          selectedSectionUuid: response.selected_section_uuid,
          sections: response.sections.map((section) => ({
            url,
            uuid: section.uuid,
            body: section.body,
            slug: section.slug,
            statuteAbbreviation: section.statute_abbreviation,
            title: section.title,
            referer: section.referer,
          })),
        }
      },
    }),
    searchResources: builder.query<ResourceSearchResults, string>({
      query: (query) => ({
        url: "/resources/search/",
        params: { query },
      }),
      transformResponse: (response: ResourceSearchResultsResponse) => {
        return toResourceSearchResult(response)
      },
    }),
    /**
     * USERS
     */
    searchUsers: builder.query<
      UserSearchResult[],
      { chapterId: UUID; query: string }
    >({
      query: ({ query, chapterId }) => ({
        url: "/pad/username-typeahead",
        params: { query, chapterId },
      }),
      transformResponse: (response: UserSearchResultResponse[]) =>
        response.map((u) => toUserSearchResult(u)),
    }),
    /**
     * DOCUMENT IMPORTS
     */
    createImportFlow: builder.mutation<
      ImportFlowDetail,
      { files: File[]; scriptId: UUID }
    >({
      query: ({ files, scriptId }) => {
        const form = new FormData()
        for (const file of files) {
          form.append("files", file, file.name)
        }
        form.append("script_id", scriptId)
        form.append("script_id", scriptId)
        return {
          url: "/doc-import-flows/",
          method: "POST",
          body: form,
          formData: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      },
      transformResponse(response: ImportFlowDetailResponse): ImportFlowDetail {
        return {
          id: response.id,
          scriptId: response.script_id,
          scriptUrl: response.script_url,
          status: response.status,
          startedAt: response.started_at,
          finishedAt: response.finished_at,
        }
      },
    }),
    deleteImportFlow: builder.mutation<void, { importFlowId: UUID }>({
      query: ({ importFlowId }) => ({
        url: `/doc-import-flows/${importFlowId}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { importFlowId }) => [
        { type: "ImportFlow", id: importFlowId },
      ],
    }),
    getImportFlowDetails: builder.query<ImportFlowDetail, UUID>({
      query: (importFlowId) => `/doc-import-flows/${importFlowId}`,
      providesTags: (_, __, id) => [{ type: "ImportFlow", id }],
      transformResponse: (response: ImportFlowDetailResponse) => {
        return {
          id: response.id,
          scriptId: response.script_id,
          scriptUrl: response.script_url,
          status: response.status,
          startedAt: response.started_at,
          finishedAt: response.finished_at,
        }
      },
    }),

    // USER PROFILE
    getUserInfo: builder.query<UserSettings, { userId: number }>({
      query: ({ userId }) => `user/${userId}/`,
      transformResponse: (response: UserSettingsResponse) => {
        return {
          id: response.id,
          titlePrefix: response.title_prefix,
          isAuthor: response.is_author,
          titleSuffix: response.title_suffix,
          firstName: response.first_name,
          lastName: response.last_name,
          bio: response.bio,
          website: response.website,
          pageAvailability: response.page_availability,
          profilePictureUrl: response.profile_picture,
          semester: response.semester,
          profilePicture: response.profile_picture,
          initials: response.initials,
          institutions: toUserSettingsInstitutions(response.institutions),
          username: response.username,
        }
      },
      providesTags: ["UserProfile"],
    }),
    updateUserData: builder.mutation({
      query: ({ userId, data }) => ({
        url: `user/${userId}/`,
        method: "PATCH",
        body: {
          title_prefix: data?.titlePrefix,
          title_suffix: data?.titleSuffix,
          first_name: data?.firstName,
          last_name: data?.lastName,
          bio: data?.bio,
          website: data?.website,
          page_availability: data?.pageAvailability,
          semester: data?.semester,
          profile_picture: data?.profilePicture,
        },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    deleteUserAccount: builder.mutation<
      void,
      { userId: number; userName: string; password: string }
    >({
      query: ({ userId, userName, password }) => ({
        url: `user/${userId}/delete-account/`,
        method: "POST",
        body: { password: password, username: userName },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    getUserEmails: builder.query<UserSettingsEmail[], { userId: number }>({
      query: ({ userId }) => `user/${userId}/email-addresses/`,
      providesTags: ["UserProfile"],
    }),
    addUserEmailAddress: builder.mutation<
      void,
      { userId: number; email: string }
    >({
      query: ({ userId, email }) => ({
        url: `user/${userId}/email-addresses/`,
        method: "POST",
        body: { user: userId, email: email },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    deleteUserEmail: builder.mutation<void, { userId: number; emailId: UUID }>({
      query: ({ userId, emailId }) => ({
        url: `user/${userId}/email-addresses/${emailId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserProfile"],
    }),
    switchPrimaryEmail: builder.mutation<
      void,
      { userId: number; email: string }
    >({
      query: ({ userId, email }) => ({
        url: `user/${userId}/make-email-as-primary/`,
        method: "POST",
        body: { new_email: email },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    updateUserPassword: builder.mutation({
      query: ({ userId, oldPassword, newPassword, confirmPassword }) => ({
        url: `user/${userId}/update-password/`,
        method: "POST",
        body: {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: confirmPassword,
        },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    leaveInstitution: builder.mutation({
      query: ({ userId, institutionId }) => ({
        url: `user/${userId}/leave-institution/`,
        method: "POST",
        body: { institution_id: institutionId },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    updateUserProfilePicture: builder.mutation({
      query: ({ file, userId }) => {
        const form = new FormData()
        form.append("file", file, file.name)
        return {
          url: `user/${userId}/update-profile-picture/`,
          method: "POST",
          body: form,
          formData: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      },
      invalidatesTags: ["UserProfile"],
    }),
    getInstitutions: builder.query({
      query: () => `institutions/`,
      transformResponse: (response: UserSettingsInstitutionResponse[]) =>
        response.map((i) => toInstitutionInfo(i)),
    }),

    // INSTITUTION MANAGEMENT
    getInstitutionUsers: builder.query<
      InstitutionUsers,
      {
        institutionId: number
        page: number
        perPage: number
        searchQuery?: string
      }
    >({
      query: ({ institutionId, page, perPage, searchQuery }) => {
        const queryParameters =
          `page=${page}&per_page=${perPage}` +
          (searchQuery ? `&query=${searchQuery}` : "")
        return `institutions/${institutionId}/users/?${queryParameters}`
      },
      transformResponse: (response: InstitutionUsersResponse) => {
        return {
          count: response.count,
          results: response.results.map((u) => toInstitutionUsers(u)),
        }
      },
      providesTags: ["InstitutionUsers"],
    }),
    getInstitutionInfo: builder.query<
      InstitutionInfo,
      { institutionId: number }
    >({
      query: ({ institutionId }) => `institutions/${institutionId}/`,
      transformResponse: (response: InstitutionInfoResponse) => {
        return {
          id: response.id,
          name: response.name,
          domains: response.domains,
          country: response.country,
          slug: response.slug,
          profilePictureUrl: response.profile_picture,
          about: response.about,
          website: response.website,
        }
      },
      providesTags: ["Institution"],
    }),
    updateInstitutionInfo: builder.mutation({
      query: ({ institutionId, data }) => ({
        url: `institutions/${institutionId}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Institution"],
    }),
    createInstitutionDomain: builder.mutation<
      void,
      { institutionId: number; newDomain: string }
    >({
      query: ({ institutionId, newDomain }) => ({
        url: `institutions/${institutionId}/domains/`,
        method: "POST",
        body: { name: newDomain, institution: institutionId },
      }),
      invalidatesTags: ["Institution"],
    }),
    deleteInstitutionDomain: builder.mutation<
      void,
      { domainId: UUID; institutionId: number }
    >({
      query: ({ institutionId, domainId }) => ({
        url: `institutions/${institutionId}/domains/${domainId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Institution"],
    }),
    getInstitutionUserTypeahead: builder.query<
      InstitutionUserTypeahead[],
      { institutionId: number; query: string }
    >({
      query: ({ institutionId, query }) =>
        `institutions/${institutionId}/typeahead?query=${query}`,
      transformResponse: (response: InstitutionUserTypeaheadResponse[]) =>
        response.map((u) => typeAheadInstitutionUsers(u)),
    }),
    makeInstitutionAdminUser: builder.mutation<
      void,
      { institutionId: number; userId: number; toAdmin: boolean }
    >({
      query: ({ institutionId, userId, toAdmin }) => ({
        url: `institutions/${institutionId}/users/${userId}/`,
        method: "PUT",
        body: { admin: toAdmin },
      }),
      invalidatesTags: ["InstitutionUsers"],
    }),
    addInstitutionUsers: builder.mutation<
      void,
      { institutionId: number; userIds: number[] }
    >({
      query: ({ institutionId, userIds }) => ({
        url: `institutions/${institutionId}/users/add/`,
        method: "POST",
        body: { user_ids: userIds },
      }),
      invalidatesTags: ["InstitutionUsers"],
    }),
    inviteInstitutionUsers: builder.mutation<
      void,
      { institutionId: number; emailsToInvite: string[] }
    >({
      query: ({ institutionId, emailsToInvite }) => ({
        url: `institutions/${institutionId}/invite/`,
        method: "POST",
        body: { emails: emailsToInvite },
      }),
      invalidatesTags: ["InstitutionUsers"],
    }),
    verifyUsersInInstitution: builder.mutation<
      VerifyUsersInInstitution,
      {
        institutionId: number
        emailsToSubmit: string[]
      }
    >({
      query: ({ institutionId, emailsToSubmit }) => ({
        url: `institutions/${institutionId}/invite/verify/`,
        method: "POST",
        body: { emails: emailsToSubmit },
      }),
      transformResponse: (rawResponse: VerifyUsersInInstitutionResponse) => {
        const transformedResponse: VerifyUsersInInstitution = {}
        for (const [email, responseData] of Object.entries(rawResponse)) {
          transformedResponse[email] =
            toVerifyUsersInInstitutionResponse(responseData)
        }
        return transformedResponse
      },
    }),
    removeInstitutionUser: builder.mutation({
      query: ({ institutionId, userId }) => ({
        url: `institutions/${institutionId}/users/${userId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["InstitutionUsers"],
    }),
    // DOCUMENT IMPORTS
    getImportDocumentDetails: builder.query<ImportDocumentDetail[], UUID>({
      query: (importFlowId) =>
        `/doc-import-flows/${importFlowId}/document-details`,
      transformResponse: (response: ImportDocumentDetailResponse[]) =>
        response.map((document) => {
          return {
            id: document.id,
            originalFilename: document.original_filename,
            charCount: document.char_count,
            tokenCount: document.token_count,
            splitPoints: (document.split_points || []).map((splitPoint) => ({
              id: splitPoint.id,
              headingLevel: splitPoint.heading_level,
              headingTitle: splitPoint.heading_title,
              charIndex: splitPoint.char_index,
              tokenIndex: splitPoint.token_index,
              charCount: splitPoint.char_count,
              tokenCount: splitPoint.token_count,
              sectionCharCount: splitPoint.section_char_count,
              sectionTokenCount: splitPoint.section_token_count,
            })),
          }
        }),
    }),
    updateSplitDecision: builder.mutation<
      ImportFlowDetail,
      {
        splitDetails: ImportDocumentSplitDetail[]
        importFlowId: UUID
      }
    >({
      query: ({ splitDetails, importFlowId }) => {
        const body = splitDetails.map((splitDetail) => ({
          document_id: splitDetail.documentId,
          heading_level_adjustment: splitDetail.headingLevelAdjustment,
          omit_primary_heading: splitDetail.omitPrimaryHeading,
          split_name: splitDetail.splitName,
          start_split_point_id: splitDetail.startSplitPointId,
          upto_split_point_id: splitDetail.uptoSplitPointId,
          strip_heading_numbering: splitDetail.stripHeadingNumbering,
        }))
        return {
          url: `/doc-import-flows/${importFlowId}/split-decision`,
          method: "PUT",
          body: body,
        }
      },
      invalidatesTags: (_, __, { importFlowId }) => [
        { type: "ImportFlow", id: importFlowId },
      ],
    }),
    /**
     * Flashcards
     */
    createFlashcardDeck: builder.mutation({
      query: (title: string) => ({
        url: `/deck/`,
        method: "POST",
        body: { title },
      }),
      invalidatesTags: [{ type: "FlashcardDecks", id: "all" }],
    }),
    getFlashcardDecks: builder.query<FlashcardDeck[], void>({
      query: () => `/deck/`,
      providesTags: (decks) => [
        { type: "FlashcardDecks", id: "all" },
        ...(decks?.map((deck) => ({
          type: "FlashcardDecks" as const,
          id: deck.id,
        })) ?? []),
      ],
      transformResponse: (response: FlashcardDeckResponse[]) =>
        response.map((deck) => toFlashcardDeck(deck)),
    }),
    getFlashcardDeck: builder.query<FlashcardDeck, { deckId: UUID }>({
      query: ({ deckId }) => `/deck/${deckId}/`,
      providesTags: (_, __, { deckId }) => [
        { type: "FlashcardDecks", id: deckId },
      ],
      transformResponse: (response: FlashcardDeckResponse) =>
        toFlashcardDeck(response),
    }),
    deleteFlashcardDeck: builder.mutation<void, { deckId: UUID }>({
      query: ({ deckId }) => ({
        url: `/deck/${deckId}/`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "FlashcardDecks" }],
    }),
    getStudySession: builder.query<StudySession, { sessionId: UUID }>({
      query: ({ sessionId }) => `/studysession/${sessionId}/`,
      providesTags: (_, __, arg) => [
        { type: "StudySession", id: arg.sessionId },
      ],
      transformResponse: (response: StudySessionResponse) =>
        toStudySession(response),
    }),
    createStudySession: builder.query<
      StudySession,
      { deckId: UUID; ignoreDueDate?: boolean }
    >({
      query: ({ deckId, ignoreDueDate }) => ({
        url: `/studysession/`,
        body: { deckId, ignoreDueDate: ignoreDueDate ?? false },
        method: "POST",
      }),
      providesTags: () => [{ type: "StudySession" }],
      transformResponse: (response: StudySessionResponse) =>
        toStudySession(response),
    }),
    updateFlashcardKnowledge: builder.mutation<
      void,
      { flashcardId: UUID; ease: FlashcardReportLevel; sessionId: UUID }
    >({
      query: ({ flashcardId, ease, sessionId }) => ({
        url: `/studysession/${sessionId}/`,
        method: "PUT",
        body: {
          reported_ease: ease,
          flashcard: flashcardId,
          session: sessionId,
        },
      }),
      invalidatesTags: (_, __, { sessionId }) => [
        {
          type: "StudySession",
          id: sessionId,
        },
      ],
    }),
    updateFlashcardDeck: builder.mutation<
      void,
      { deckId: UUID; title: string; description: string }
    >({
      query: ({ deckId, title, description }) => ({
        url: `/deck/${deckId}/`,
        method: "PATCH",
        body: { title, description },
      }),
      invalidatesTags: () => [{ type: "FlashcardDecks" }],
    }),
    getFlashcardDeckPermissions: builder.query<
      DocumentContainerPermissions,
      UUID
    >({
      query: (deckId) => `/deck/${deckId}/list-permissions/`,
      providesTags: () => [{ type: "FlashcardDeckPermissions" }],
    }),
    getFlashcardDeckPermissionsSearch: builder.query<
      PermissionEntitySearchResult,
      { resourceId: UUID; query: string }
    >({
      query: ({ resourceId, query }) => ({
        url: `/deck/${resourceId}/search/`,
        params: { query },
      }),
    }),
    addEntityToFlashcardDeckPermissions: builder.mutation<
      void,
      PermissionEntity
    >({
      query: ({ resourceId, entity, entityType, permission }) => ({
        url: `/deck/${resourceId}/add-permissions/`,
        method: "POST",
        body: {
          entity: entity,
          entity_type: entityType,
          permission: permission,
        },
      }),
      invalidatesTags: () => [{ type: "FlashcardDeckPermissions" }],
    }),
    removeEntityFromFlashcardDeckPermissions: builder.mutation<
      void,
      { resourceId: UUID; entity: number; entityType: string }
    >({
      query: ({ resourceId, entity, entityType }) => ({
        url: `/deck/${resourceId}/remove-permissions/`,
        method: "DELETE",
        body: {
          entity: entity,
          entity_type: entityType,
        },
      }),
      invalidatesTags: () => [{ type: "FlashcardDeckPermissions" }],
    }),
    updateEntityOnFlashcardDeckPermissions: builder.mutation<
      void,
      PermissionEntity
    >({
      query: ({ resourceId, entity, entityType, permission }) => ({
        url: `/deck/${resourceId}/update-permissions/`,
        method: "PUT",
        body: {
          entity: entity,
          entity_type: entityType,
          permission: permission,
        },
      }),
      invalidatesTags: () => [{ type: "FlashcardDeckPermissions" }],
    }),
    toggleFlashcardDeckSaved: builder.mutation<void, { deckId: UUID }>({
      query: ({ deckId }) => ({
        url: `/deck/${deckId}/toggle-saved/`,
        method: "POST",
      }),
      invalidatesTags: () => [{ type: "FlashcardDecks" }],
    }),
    addFlashcardToHighlight: builder.mutation<
      Flashcard,
      { revisionId: UUID; highlightId: UUID; deckId: UUID }
    >({
      query: ({ highlightId, deckId }) => ({
        url: `/pad/highlights/${highlightId}/flashcards`,
        method: "POST",
        body: {
          deck_id: deckId,
        },
      }),
      transformResponse(response: FlashcardResponse) {
        return toFlashcard(response)
      },
      invalidatesTags: (flashcard) => [
        { type: "FlashcardDecks", id: flashcard?.deckId },
      ],
    }),
    createFlashcard: builder.mutation<Flashcard, { deckId: UUID }>({
      query: ({ deckId }) => ({
        url: `/deck/${deckId}/flashcard`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { deckId }) => [
        { type: "FlashcardDecks", id: deckId },
      ],
    }),
    createMagicFlashcardText: builder.mutation<FlashcardText, { text: string }>(
      {
        query: ({ text }) => ({
          url: `/pad/magic/flashcard/`,
          method: "POST",
          body: {
            text,
          },
        }),
      }
    ),
    getFlashcard: builder.query<Flashcard, UUID>({
      query: (flashcardId) => `/flashcard/${flashcardId}/`,
      providesTags: (_, __, id) => [{ type: "Flashcards", id }],
      transformResponse: (response: FlashcardResponse) => {
        return toFlashcard(response)
      },
    }),
    deleteFlashcard: builder.mutation<
      void,
      { flashcardId: UUID; highlightId?: UUID; revisionId?: UUID }
    >({
      query: ({ flashcardId }) => ({
        url: `/flashcard/${flashcardId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { flashcardId }) => [
        { type: "Flashcards", id: flashcardId },
      ],
      onQueryStarted: async ({ flashcardId }, { dispatch, queryFulfilled }) => {
        const { data: flashcard } = await dispatch(
          apiSlice.endpoints.getFlashcard.initiate(flashcardId, {
            forceRefetch: false,
          })
        )
        if (!flashcard) return

        const optimisticUpdate = await dispatch(
          apiSlice.util.updateQueryData(
            "getFlashcardDeck",
            {
              deckId: flashcard.deckId,
            },
            (deck) => {
              deck.flashcards = deck.flashcards.filter(
                (f) => f.id !== flashcard.id
              )
            }
          )
        )

        try {
          await queryFulfilled
        } catch (_) {
          optimisticUpdate.undo()
        }
      },
    }),
    updateFlashcard: builder.mutation<
      Flashcard,
      { flashcardId: UUID; deckId: UUID }
    >({
      query: ({ flashcardId, deckId }) => ({
        url: `/flashcard/${flashcardId}/`,
        method: "PATCH",
        body: {
          deck_id: deckId,
        },
      }),
      transformResponse: (response: FlashcardResponse) => {
        return toFlashcard(response)
      },
      invalidatesTags: (_, __, { flashcardId }) => [
        { type: "Flashcards", id: flashcardId },
      ],
    }),
    getFlashcardRevision: builder.query<
      DocumentRevision,
      { flashcardId: UUID; revisionId: UUID }
    >({
      query: ({ flashcardId, revisionId }) =>
        `/flashcard/${flashcardId}/revisions/${revisionId}`,
      providesTags: (_, __, { revisionId }) => [
        { type: "Revisions", id: revisionId },
      ],
      transformResponse: (response: ChapterRevisionResponse, _, args) => {
        return {
          id: args.revisionId,
          documentId: args.flashcardId,
          name: response.name,
          status: response.status,
          snapshot: {
            id: response.snapshot.id,
            isInitial: response.snapshot.is_initial,
            version: response.snapshot.version,
            shortId: response.snapshot.short_id,
            content: response.snapshot.content,
          },
        }
      },
    }),
    getFlashcardLatestSteps: builder.query<
      Steps,
      { flashcardId: UUID; revisionId: UUID; version: number }
    >({
      query: ({ flashcardId, version }) => ({
        url: `/flashcard/${flashcardId}/steps`,
        params: { version },
      }),
      transformResponse: (response: StepsResponse) =>
        response.map((step) => ({
          ...step,
          value: Step.fromJSON(flashcardSchema, step.value),
        })),
    }),
    /*
    Notebooks
     */
    getNotebooks: builder.query<Notebook[], void>({
      query: () => "/notebook/",
      transformResponse: (response: NotebookResponse[]) =>
        response.map((notebook) => toNotebook(notebook)),
      providesTags: () => [{ type: "Notebooks" }],
    }),
    createNotebook: builder.mutation<
      Notebook,
      { title: string; description?: string }
    >({
      query: ({ title, description }) => ({
        url: "/notebook/",
        method: "POST",
        body: { title, description },
      }),
      invalidatesTags: () => [{ type: "Notebooks" }],
    }),
    getNotebook: builder.query<Notebook, { notebookId: UUID }>({
      query: ({ notebookId }) => `/notebook/${notebookId}/`,
      providesTags: (_, __, { notebookId }) => [
        { type: "Notebooks", id: notebookId },
      ],
      transformResponse: (response: NotebookResponse) => {
        return toNotebook(response)
      },
    }),
    updateNotebook: builder.mutation<
      Notebook,
      { notebookId: UUID; title: string; description: string }
    >({
      query: ({ notebookId, title, description }) => ({
        url: `/notebook/${notebookId}/`,
        method: "PATCH",
        body: { title, description },
      }),
      invalidatesTags: (_, __, { notebookId }) => [
        { type: "Notebooks", id: notebookId },
      ],
    }),
    deleteNotebook: builder.mutation<void, { notebookId: UUID }>({
      query: ({ notebookId }) => ({
        url: `/notebook/${notebookId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { notebookId }) => [
        { type: "Notebooks", id: notebookId },
      ],
    }),
    toggleNotebookSaved: builder.mutation<void, { notebookId: UUID }>({
      query: ({ notebookId }) => ({
        url: `/notebook/${notebookId}/toggle-saved/`,
        method: "POST",
      }),
      invalidatesTags: () => [{ type: "Notebooks" }],
    }),
    getNotebookPermissions: builder.query<DocumentContainerPermissions, UUID>({
      query: (notebookId) => `/notebook/${notebookId}/list-permissions/`,
      providesTags: () => [{ type: "NotebookPermissions" }],
    }),
    getNotebookPermissionsSearch: builder.query<
      PermissionEntitySearchResult,
      { resourceId: UUID; query: string }
    >({
      query: ({ resourceId, query }) => ({
        url: `/notebook/${resourceId}/search/`,
        params: { query },
      }),
    }),
    addEntityToNotebookPermissions: builder.mutation<void, PermissionEntity>({
      query: ({ resourceId, entity, entityType, permission }) => ({
        url: `/notebook/${resourceId}/add-permissions/`,
        method: "POST",
        body: {
          entity: entity,
          entity_type: entityType,
          permission: permission,
        },
      }),
      invalidatesTags: () => [{ type: "NotebookPermissions" }],
    }),
    removeEntityFromNotebookPermissions: builder.mutation<
      void,
      { resourceId: UUID; entity: number; entityType: PermissionEntityType }
    >({
      query: ({ resourceId, entity, entityType }) => ({
        url: `/notebook/${resourceId}/remove-permissions/`,
        method: "DELETE",
        body: {
          entity: entity,
          entity_type: entityType,
        },
      }),
      invalidatesTags: () => [{ type: "NotebookPermissions" }],
    }),
    updateEntityOnNotebookPermissions: builder.mutation<void, PermissionEntity>(
      {
        query: ({ resourceId, entity, entityType, permission }) => ({
          url: `/notebook/${resourceId}/update-permissions/`,
          method: "PUT",
          body: {
            entity: entity,
            entity_type: entityType,
            permission: permission,
          },
        }),
        invalidatesTags: () => [{ type: "NotebookPermissions" }],
      }
    ),
    createNote: builder.mutation<
      Note,
      { notebookId: UUID; title: string; description: string }
    >({
      query: ({ notebookId, title, description }) => ({
        url: `/notebook/${notebookId}/note/`,
        method: "POST",
        body: {
          notebook_id: notebookId,
          title: title,
          description: description,
        },
      }),
      invalidatesTags: (_, __, { notebookId }) => [
        { type: "Notebooks", id: notebookId },
        { type: "Notebooks" },
      ],
    }),
    getNote: builder.query<Note, UUID>({
      query: (noteId) => `/note/${noteId}/`,
      providesTags: (_, __, noteId) => [{ type: "Notes", id: noteId }],
      transformResponse: (response: NoteResponse) => {
        return toNote(response)
      },
    }),
    updateNote: builder.mutation<
      Note,
      { noteId: UUID; notebookId: UUID; title: string; description: string }
    >({
      query: ({ noteId, notebookId, title, description }) => ({
        url: `/note/${noteId}/`,
        method: "PATCH",
        body: {
          title,
          description,
          notebook_id: notebookId,
        },
      }),
      invalidatesTags: (_, __, { noteId }) => [{ type: "Notes", id: noteId }],
    }),
    logNoteOpened: builder.mutation<void, { noteId: UUID }>({
      query: ({ noteId }) => ({
        url: `/note/${noteId}/opened/`,
        method: "POST",
      }),
    }),
    deleteNote: builder.mutation<void, { noteId: UUID; notebookId: UUID }>({
      query: ({ noteId }) => ({
        url: `/note/${noteId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { notebookId }) => [
        { type: "Notebooks", id: notebookId },
      ],
    }),
    getNoteRevision: builder.query<
      DocumentRevision,
      { noteId: UUID; revisionId: UUID }
    >({
      query: ({ noteId, revisionId }) =>
        `/note/${noteId}/revisions/${revisionId}`,
      providesTags: (_, __, { revisionId }) => [
        { type: "Revisions", id: revisionId },
      ],
      transformResponse: (response: ChapterRevisionResponse, _, args) => {
        return {
          id: args.revisionId,
          documentId: args.noteId,
          name: response.name,
          status: response.status,
          snapshot: {
            id: response.snapshot.id,
            isInitial: response.snapshot.is_initial,
            version: response.snapshot.version,
            shortId: response.snapshot.short_id,
            content: response.snapshot.content,
          },
        }
      },
    }),
    /*
    Magic Linking
     */
    getLinkableResources: builder.query<LinkableResourcesResponse, void>({
      query: () => ({
        url: `/pad/linkables/`,
        method: "GET",
      }),
    }),
    getReferenceLinks: builder.query<
      ReferenceLinksResponse,
      {
        text: string
        referenceType: "court_decision" | "statute_section" | "all"
      }
    >({
      query: ({ text, referenceType }) => {
        return {
          url: `/pad/reference-links/`,
          method: "POST",
          body: {
            text,
            reftype: referenceType,
          },
        }
      },
    }),
  }),
})

export const {
  useGetChapterQuery,
  useLazyGetChapterQuery,
  useGetChapterRevisionListQuery,
  useLazyGetChapterRevisionListQuery,
  useGetChapterRevisionQuery,
  useLazyGetChapterRevisionQuery,
  useCreateStepsMutation,
  useGetHighlightsQuery,
  useCreateHighlightMutation,
  useDeleteHighlightMutation,
  useLazyGetHighlightsQuery,
  useGetCourtDecisionQuery,
  useGetStatuteSectionQuery,
  useLazyGetCourtDecisionQuery,
  useLazyGetStatuteSectionQuery,
  useGenerateMagicQuizMutation,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useUploadFileMutation,
  useSearchVideosQuery,
  useLazySearchVideosQuery,
  useSearchResourcesQuery,
  useLazySearchResourcesQuery,
  useCreateImportFlowMutation,
  useDeleteImportFlowMutation,
  useGetImportFlowDetailsQuery,
  useLazyGetImportDocumentDetailsQuery,
  useGetImportDocumentDetailsQuery,
  useUpdateSplitDecisionMutation,
  useGetInstitutionUsersQuery,
  useLazyGetInstitutionUsersQuery,
  useGetInstitutionUserTypeaheadQuery,
  useLazyGetInstitutionUserTypeaheadQuery,
  useMakeInstitutionAdminUserMutation,
  useAddInstitutionUsersMutation,
  useInviteInstitutionUsersMutation,
  useRemoveInstitutionUserMutation,
  useVerifyUsersInInstitutionMutation,
  useGetInstitutionInfoQuery,
  useUpdateInstitutionInfoMutation,
  useCreateInstitutionDomainMutation,
  useDeleteInstitutionDomainMutation,
  useUpdateUserDataMutation,
  useGetUserEmailsQuery,
  useAddUserEmailAddressMutation,
  useDeleteUserEmailMutation,
  useUpdateUserPasswordMutation,
  useGetInstitutionsQuery,
  useLeaveInstitutionMutation,
  useGetUserInfoQuery,
  useGetReferenceLinksQuery,
  useLazyGetReferenceLinksQuery,
  useGetLinkableResourcesQuery,
  useDeleteUserAccountMutation,
  useUpdateUserProfilePictureMutation,
  useSwitchPrimaryEmailMutation,
  useCreateFlashcardMutation,
  useAddFlashcardToHighlightMutation,
  useUpdateFlashcardDeckMutation,
  useGetFlashcardDeckPermissionsQuery,
  useUpdateFlashcardMutation,
  useDeleteFlashcardMutation,
  useGetFlashcardDecksQuery,
  useLazyGetFlashcardDecksQuery,
  useDeleteFlashcardDeckMutation,
  useGetFlashcardQuery,
  useGetFlashcardRevisionQuery,
  useGetFlashcardLatestStepsQuery,
  useGetFlashcardDeckQuery,
  useLazyGetFlashcardDeckQuery,
  useAddEntityToFlashcardDeckPermissionsMutation,
  useRemoveEntityFromFlashcardDeckPermissionsMutation,
  useUpdateEntityOnFlashcardDeckPermissionsMutation,
  useLazyGetFlashcardDeckPermissionsSearchQuery,
  useToggleFlashcardDeckSavedMutation,
  useGetStudySessionQuery,
  useCreateStudySessionQuery,
  useUpdateFlashcardKnowledgeMutation,
  // Notebooks
  useToggleNotebookSavedMutation,
  useGetNotebooksQuery,
  useCreateNotebookMutation,
  useGetNotebookQuery,
  useUpdateNotebookMutation,
  useDeleteNotebookMutation,
  useGetNotebookPermissionsQuery,
  useAddEntityToNotebookPermissionsMutation,
  useRemoveEntityFromNotebookPermissionsMutation,
  useUpdateEntityOnNotebookPermissionsMutation,
  useLazyGetNotebookPermissionsSearchQuery,
  useCreateNoteMutation,
  useGetNoteQuery,
  useLazyGetNoteQuery,
  useLogNoteOpenedMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useLazyGetNoteRevisionQuery,
} = apiSlice

/**
 * Gracefully handles defective cache updates and logs errors to Sentry.
 * Related issue: https://github.com/dskrpt/dskrpt_app/issues/834
 */

function safeguardCacheUpdate<T>(
  relatedUuid: string,
  fn: (draft: MaybeDrafted<T[]>) => void
) {
  return (draft: MaybeDrafted<T[]>) => {
    try {
      return fn(draft)
    } catch (e) {
      sendErrorToSentry(`RTK cache update failed for UUID: ${relatedUuid}`, e)
    }
  }
}

/** CACHE MUTATIONS */

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const addToHighlightsCache = (
  args: { chapter_id: UUID; revision_id: UUID },
  highlight: HighlightResponse
) => {
  return apiSlice.util.updateQueryData(
    "getHighlights",
    { chapterId: args.chapter_id, revisionId: args.revision_id },
    safeguardCacheUpdate(args.revision_id, (draft) => {
      draft.push(toHighlight(highlight))
    })
  )
}

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const removeFromHighlightsCache = (
  args: { chapter_id: UUID; revision_id: UUID },
  highlightId: UUID
) => {
  return apiSlice.util.updateQueryData(
    "getHighlights",
    { chapterId: args.chapter_id, revisionId: args.revision_id },
    safeguardCacheUpdate(args.revision_id, (draft) => {
      const index = draft.findIndex((highlight) => highlight.id === highlightId)
      if (index !== -1) draft.splice(index, 1)
    })
  )
}

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const updateInHighlightsCache = (
  args: { chapter_id: UUID; revision_id: UUID },
  highlightId: UUID,
  threadId: UUID | null
) => {
  return apiSlice.util?.updateQueryData(
    "getHighlights",
    { chapterId: args.chapter_id, revisionId: args.revision_id },
    safeguardCacheUpdate(args.revision_id, (draft) => {
      const highlight = draft.find((highlight) => highlight.id === highlightId)
      if (highlight) highlight.threadId = threadId
    })
  )
}

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const addToMessagesCache = (
  highlightId: UUID,
  message: MessageResponse
) => {
  return apiSlice.util.updateQueryData(
    "getMessages",
    highlightId,
    safeguardCacheUpdate(highlightId, (draft) => {
      draft.push(toMessage(message))
    })
  )
}

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const updateInMessagesCache = (
  highlightId: UUID,
  updatedMessage: MessageResponse
) => {
  return apiSlice.util.updateQueryData(
    "getMessages",
    highlightId,
    safeguardCacheUpdate(highlightId, (draft) => {
      const message = draft.find((message) => message.id === updatedMessage.id)
      if (message) message.content = updatedMessage.content
    })
  )
}

/**
 * Update the cache without making a request to the server.
 * This function returns an action that needs to be dispatched.
 */

export const removeFromMessagesCache = (highlightId: UUID, messageId: UUID) => {
  return apiSlice.util.updateQueryData(
    "getMessages",
    highlightId,
    safeguardCacheUpdate(highlightId, (draft) => {
      const index = draft.findIndex((message) => message.id === messageId)
      if (index !== -1) draft.splice(index, 1)
    })
  )
}

/** HELPERS */

export const toMessage = (response: MessageResponse): Message => ({
  id: response.id,
  content: response.content,
  threadId: response.thread_id,
  createdAt: response.created_at,
  createdBy: response.created_by,
  createdByName: response.created_by_name,
  mentionedUsers: response.mentioned_users,
  updatedAt: response.updated_at,
})

export const toKnowledge = (knowledge: KnowledgeResponse): Knowledge => ({
  queue: knowledge.queue,
  easeFactor: knowledge.ease_factor,
  dueAt: knowledge.due_at,
})

export const toFlashcard = (response: FlashcardResponse): Flashcard => ({
  ...response,
  publishedRevisionId: response.published_revision_id,
  draftRevisionId: response.draft_revision_id,
  deckId: response.deck_id,
  deckTitle: response.deck_title,
  deckUrl: response.deck_url,
  knowledge: response.knowledge ? toKnowledge(response.knowledge) : undefined,
  highlight: response.highlight
    ? {
        id: response.highlight.id,
        documentId: response.highlight.document_id,
      }
    : undefined,
})

export const toFlashcardDeck = (
  response: FlashcardDeckResponse
): FlashcardDeck => ({
  id: response.id,
  title: response.title,
  description: response.description,
  slug: response.slug,
  owner: response.owner,
  flashcards: response.flashcards ? response.flashcards.map(toFlashcard) : [],
  canUserEdit: response.can_user_edit,
  isUserOwner: response.is_user_owner,
  hasUserSavedDeck: response.has_user_saved_deck,
})

export const toStudySession = (response: StudySessionResponse) => ({
  uuid: response.uuid,
  nextFlashcard: {
    id: response.next_flashcard.id,
    draftRevisionId: response.next_flashcard.draft_revision_id,
    annotation: response.next_flashcard.annotation,
  },
  nextFlashcardIntervals: response.next_flashcard_intervals,
  history: response.history.map((history) => ({
    reportedEase: history.reported_ease,
    hasFollowUp: history.has_follow_up,
  })),
  deck: response.deck,
  completedAt: response.completed_at,
})

export const toHighlight = (highlight: HighlightResponse): Highlight => ({
  id: highlight.id,
  from: highlight.start,
  to: highlight.end,
  color: highlight.color,
  positionStatus: highlight.position_status,
  revisionId: highlight.revision_id,
  threadId: highlight.thread_id,
  flashcardId: highlight.flashcard_id,
  stepVersion: highlight.step_version,
  createdAt: highlight.created_at,
  createdBy: highlight.created_by,
})

export const toUserSearchResult = (
  user: UserSearchResultResponse
): UserSearchResult => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  initials: user.initials,
  username: user.username,
  profilePictureUrl: user.profile_picture,
  institutions: user.institutions,
})

export const toVideoSearchResult = (
  video: VideoSearchResultResponse["items"][number]
): VideoSearchResultItem => ({
  name: video.name,
  timeCode: video.time_code,
  platform: video.platform,
  href: video.href,
  snippet: {
    title: video.snippet.title,
    channelTitle: video.snippet.channelTitle,
    thumbnail: video.snippet.thumbnail,
  },
  videoId: video.video_id,
})

export const toResourceSearchResult = (
  result: ResourceSearchResultsResponse
): ResourceSearchResults => ({
  courtDecisions: result.court_decisions.map(toCourtDecisionSearchResult),
  sections: result.sections.map(toStatuteSectionSearchResult),
  references: result.references.map(toReferenceSearchResult),
})

const toCourtDecisionSearchResult = (
  courtDecision: CourtDecisionResourceSearchResultResponse
): CourtDecisionResourceSearchResult => ({
  uuid: courtDecision.uuid,
  courtName: courtDecision.court_name,
  title: courtDecision.title,
  date: courtDecision.date,
  docketNumber: courtDecision.docket_number,
  url: courtDecision.url,
  decisionType: courtDecision.decision_type,
  ecli: courtDecision.ecli,
  fullName: courtDecision.full_name,
})

const toStatuteSectionSearchResult = (
  section: SectionResourceSearchResultResponse
): SectionResourceSearchResult => ({
  uuid: section.uuid,
  referer: section.referer,
  title: section.title,
  body: section.body,
  slug: section.slug,
  statuteAbbreviation: section.statute_abbreviation,
  url: section.url,
  fullName: section.full_name,
})

const toReferenceSearchResult = (
  reference: ReferenceResourceSearchResultResponse
): ReferenceResourceSearchResult => ({
  uuid: reference.uuid,
  courtName: reference.court_name,
  date: reference.date,
  fileReference: reference.file_reference,
  url: reference.url,
  journalAbbreviation: reference.journal_abbreviation,
  volume: reference.volume,
  page: reference.page,
  title: reference.title,
  fullName: reference.full_name,
})

export const toInstitutionUsers = (
  user: InstitutionUserResponse
): InstitutionUser => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  initials: user.initials,
  username: user.username,
  profilePictureUrl: user.profile_picture,
  isInvited: user.is_invited,
  isAdmin: user.is_admin,
  invitation: user.invitation,
})

export const typeAheadInstitutionUsers = (
  user: InstitutionUserTypeaheadResponse
): InstitutionUserTypeahead => ({
  id: user.id,
  username: user.username,
  institutions: user.institutions,
  firstName: user.first_name,
  lastName: user.last_name,
  initials: user.initials,
  alreadyInInstitution: user.already_in_institution,
  profilePictureUrl: user.profile_picture,
  email: user.email,
  type: "user",
})

export const toVerifyUsersInInstitutionResponse = (
  responseData: VerifyUsersInInstitutionResponse["email"]
): VerifyUsersInInstitution["email"] => {
  return {
    isUser: responseData.is_user,
    isInInstitution: responseData.is_in_institution,
    user: responseData.user
      ? {
          id: responseData.user.id,
          firstName: responseData.user.first_name,
          lastName: responseData.user.last_name,
          initials: responseData.user.initials,
          username: responseData.user.username,
          email: responseData.user.email,
        }
      : undefined,
  }
}

export const toInstitutionInfo = (
  institution: UserSettingsInstitutionResponse
): UserSettingsInstitution => ({
  id: institution.id,
  name: institution.name,
  domains: institution.domains,
  country: institution.country,
  slug: institution.slug,
  profilePictureUrl: institution.profile_picture,
  about: institution.about,
  website: institution.website,
  initials: institution.initials,
  ssoClientId: institution.sso_client_id,
  isSsoActive: institution.is_sso_active,
})

const toUserSettingsInstitutions = (
  institutions: UserSettingsInstitutionResponse[]
): UserSettingsInstitution[] => {
  return institutions.map(toInstitutionInfo)
}

export const toNotebook = (response: NotebookResponse): Notebook => ({
  id: response.id,
  title: response.title,
  description: response.description,
  slug: response.slug,
  owner: response.owner,
  isUserOwner: response.is_user_owner,
  hasUserSavedNotebook: response.has_user_saved_notebok,
  canUserEdit: response.can_user_edit,
  notes: response.notes?.map(toNote) ?? [],
})

export const toNote = (response: NoteResponse): Note => ({
  id: response.id,
  publishedRevisionId: response.published_revision_id,
  draftRevisionId: response.draft_revision_id,
  notebookId: response.notebook_id,
  notebookTitle: response.notebook_title,
  notebookUrl: response.notebook_url,
  title: response.title,
  description: response.description,
  url: response.url,
  isLastUsed: response.is_last_used,
})
