import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"
import { validateMediaURL } from "@/utils/url"

export function pdfSchema(): NodeSchema<"pdf"> {
  return {
    pdf: {
      inline: false,
      attrs: {
        ...CommonBlockAttrs.spec,
        src: {},
        title: { default: null },
      },
      group: "block",
      atom: true,
      parseDOM: [
        {
          tag: "embed[src]",
          getAttrs(dom) {
            if (!(dom instanceof HTMLElement)) return {}
            return {
              ...CommonBlockAttrs.parse?.(dom),
              src: dom.getAttribute("src"),
              title: dom.getAttribute("title"),
            }
          },
        },
      ],
      toDOM(node) {
        const { src, title } = node.attrs
        const mediaURL = validateMediaURL(src)

        return mediaURL
          ? [
              "embed",
              {
                ...CommonBlockAttrs.serialize(node),
                type: "application/pdf",
                src: mediaURL,
                title,
              },
            ]
          : ["embed"]
      },
    },
  }
}
