import { type PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { apiSlice } from "./api"

import type { UUID } from "@/store/UUID"
import { flashcardToggled } from "@/store/thunks/flashcardToggled"

export type FlashcardOpenState = Record<
  UUID,
  { flashcardId: UUID; isOpen: boolean; isEditing: boolean; isLoading: boolean }
>

type FlashcardsState = Record<UUID, FlashcardOpenState>

export const flashcardsSlice = createSlice({
  name: "flashcards",
  initialState: {} as FlashcardsState,
  reducers: {
    flashcardToggled(
      state,
      action: PayloadAction<{
        highlightId: UUID
        revisionId: UUID
        flashcardId: UUID
      }>
    ) {
      const { highlightId, flashcardId, revisionId } = action.payload

      if (!state[revisionId]) state[revisionId] = {}
      const revisionFlashcards = state[revisionId] ?? {}

      const flashcard = revisionFlashcards[highlightId] ?? {
        isOpen: false,
        isEditing: false,
        flashcardId,
        isLoading: false,
      }

      flashcard.isOpen = !flashcard.isOpen

      revisionFlashcards[highlightId] = flashcard
      state[revisionId] = revisionFlashcards
    },
    flashcardEditClicked(
      state,
      action: PayloadAction<{ highlightId: UUID; revisionId: UUID }>
    ) {
      const { highlightId, revisionId } = action.payload

      if (!state[revisionId]) state[revisionId] = {}
      const revisionFlashcards = state[revisionId] ?? {}
      const flashcardState = revisionFlashcards[highlightId]
      if (!flashcardState) return

      revisionFlashcards[highlightId] = {
        ...flashcardState,
        isEditing: true,
      }
    },
    flashcardSaved(
      state,
      action: PayloadAction<{ highlightId: UUID; revisionId: UUID }>
    ) {
      const { highlightId, revisionId } = action.payload

      if (!state[revisionId]) state[revisionId] = {}
      const revisionFlashcards = state[revisionId] ?? {}
      const flashcardState = revisionFlashcards[highlightId]
      if (!flashcardState) return

      revisionFlashcards[highlightId] = {
        ...flashcardState,
        isEditing: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(flashcardToggled.fulfilled, (state, action) => {
      const { revisionId, highlightId } = action.meta.arg
      const revision = state[revisionId]
      if (!revision) return

      const highlight = revision[highlightId]
      if (!highlight) return

      highlight.isLoading = false
    })
    builder.addMatcher(
      apiSlice.endpoints.addFlashcardToHighlight.matchFulfilled,
      (state, action) => {
        const { revisionId, highlightId } = action.meta.arg.originalArgs
        const revision = state[revisionId] ?? {}
        revision[highlightId] = {
          flashcardId: action.payload.id,
          isOpen: false,
          isEditing: true,
          isLoading: true,
        }
        state[revisionId] = revision
      }
    )
    builder.addMatcher(
      apiSlice.endpoints.getHighlights.matchFulfilled,
      (state, action) => {
        const { revisionId } = action.meta.arg.originalArgs
        const highlights = action.payload
        const revision = state[revisionId] ?? {}
        for (const highlight of highlights) {
          if (!highlight.flashcardId) continue

          revision[highlight.id] = {
            flashcardId: highlight.flashcardId,
            isOpen: !!revision[highlight.id]?.isOpen,
            isEditing: !!revision[highlight.id]?.isEditing,
            isLoading: !!revision[highlight.id]?.isLoading,
          }
        }
        state[revisionId] = revision
      }
    )
    builder.addMatcher(
      isAnyOf(
        apiSlice.endpoints.deleteHighlight.matchFulfilled,
        apiSlice.endpoints.deleteFlashcard.matchFulfilled
      ),
      (state, action) => {
        const { revisionId, highlightId } = action.meta.arg.originalArgs
        if (!revisionId || !highlightId) return
        const revision = state[revisionId]
        if (!revision) return
        const highlight = revision[highlightId]
        if (!highlight) return
        delete revision[highlightId]
      }
    )
  },
})
