import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

import { apiSlice } from "./api"

import type { UUID } from "@/store/UUID"

type HighlightUUID = UUID
export type ThreadOpenState = Record<HighlightUUID, { isOpen: boolean }>
type ThreadsState = {
  [key: UUID]: ThreadOpenState
}

export const threadsSlice = createSlice({
  name: "threads",
  initialState: {} as ThreadsState,
  reducers: {
    threadOpened(
      state,
      action: PayloadAction<{ highlightId: UUID; revisionId: UUID }>
    ) {
      const { highlightId, revisionId } = action.payload
      if (!state[revisionId]) state[revisionId] = {}
      const revisionThreads = state[revisionId] || {}
      revisionThreads[highlightId] = { isOpen: true }
    },
    threadClosed(
      state,
      action: PayloadAction<{ highlightId: UUID; revisionId: UUID }>
    ) {
      const { revisionId, highlightId } = action.payload
      const revisionThreads = state[revisionId]
      if (!revisionThreads) return
      const thread = revisionThreads[highlightId]
      if (thread) thread.isOpen = false
    },
    threadRemoved(state, action: PayloadAction<{ highlightId: UUID }>) {
      const { highlightId } = action.payload
      Object.values(state).forEach((revisionThreads) => {
        delete revisionThreads[highlightId]
      })
    },
    toggleThreadsVisibility(
      state,
      action: PayloadAction<{ revisionId: UUID }>
    ) {
      const { revisionId } = action.payload
      const revisionThreads = state[revisionId]
      if (!revisionThreads) return
      const threads = Object.values(revisionThreads)
      const hasOpenThreads = threads.some(({ isOpen }) => isOpen)
      threads.forEach((thread) => {
        thread.isOpen = !hasOpenThreads
      })
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.deleteHighlight.matchFulfilled,
      (state, action) => {
        delete state[action.meta.arg.originalArgs.highlightId]
      }
    )
  },
})
