import { sendErrorToSentry } from "./sentry"

import type { UUID } from "@/store/UUID.ts"
import { PanelType } from "@/store/slices/panels"
import type { ChapterResponse } from "@/types/api.ts"
import { VideoPlatform } from "@/types/api.ts"

const CHAPTER_REGEX = /\/chapter\/(?<uuid>[0-9a-fA-F-_]+)\/?$/ // Note: /chapter/ not /chapters/
const COURT_DECISION_REGEX = /\/resources\/decisions\/([0-9a-fA-F-_]+)\/?$/
const REFERENCE_REGEX = /\/resources\/references\/([0-9a-fA-F-_]+)\/?$/
const STATUTE_SECTION_REGEX = /\/resources\/sections\/([0-9a-fA-F-_]+)\/?$/
const PDF_DOCUMENT_REGEX = /^\/media\/[a-z0-9A-Z-_]+.pdf$/
// Regex to support the old format of /resources/statutes/<statute_slug>/<section_slug>/
const STATUTE_SECTION_REGEX_OLD =
  /\/resources\/statutes\/[a-z0-9A-Z-_]+\/[a-z0-9A-Z-_]+\/?$/

const matchesUrlPattern = (url: string, pattern: RegExp) => {
  try {
    const parsedUrl = new URL(url)
    return !!parsedUrl.pathname.match(pattern)
  } catch {
    return !!url.match(pattern)
  }
}

const isInternalURL = (url: string) =>
  url.startsWith(`${window.location.origin}/`)

export const isValidURL = (url: string) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

export const parseURL = async (url?: string) => {
  if (!url) return undefined

  if (matchesUrlPattern(url, REFERENCE_REGEX)) {
    return {
      url,
      type: "internal" as const,
      resourceType: PanelType.COURT_DECISION as const,
      resourceUrl: url,
      scrollToBlock: undefined,
    }
  }

  if (matchesUrlPattern(url, COURT_DECISION_REGEX)) {
    return {
      url,
      type: "internal" as const,
      resourceType: PanelType.COURT_DECISION as const,
      resourceUrl: url,
      scrollToBlock: undefined,
    }
  }

  if (
    matchesUrlPattern(url, STATUTE_SECTION_REGEX) ||
    matchesUrlPattern(url, STATUTE_SECTION_REGEX_OLD)
  ) {
    return {
      url,
      type: "internal" as const,
      resourceType: PanelType.STATUTE_SECTION as const,
      resourceUrl: url,
      scrollToBlock: undefined,
    }
  }

  if (matchesUrlPattern(url, CHAPTER_REGEX)) {
    const chapterUrl = new URL(url)
    const chapterId = chapterUrl.pathname.match(CHAPTER_REGEX)?.groups?.uuid
    if (!chapterId) return undefined
    return {
      type: "internal" as const,
      resourceType: PanelType.VIEWER as const,
      resourceUrl: url,
      documentId: chapterId as UUID,
      revisionId: chapterUrl.searchParams.get("r") as UUID,
      scrollToBlock: chapterUrl.hash.slice(1) as UUID,
    }
  }

  // This covers links in chapters, usually to other chapters, that still use the old URL
  // schema of /<author_username>/<script_slug>/<chapter_slug>/
  if (isInternalURL(url)) {
    const resolvedDocument = await resolveDocumentUrl(url)
    if (resolvedDocument) {
      return {
        type: "internal" as const,
        resourceType: PanelType.VIEWER as const,
        resourceUrl: url,
        documentId: resolvedDocument.documentId,
        revisionId: resolvedDocument.revisionId,
        scrollToBlock: resolvedDocument.scrollToBlock,
      }
    }
  }

  if (matchesUrlPattern(url, PDF_DOCUMENT_REGEX)) {
    return {
      type: "internal" as const,
      resourceType: PanelType.PDF_VIEWER as const,
      resourceUrl: url,
      scrollToBlock: undefined,
    }
  }

  // Case for external links comes last
  if (isValidURL(url)) {
    const { hostname, search, searchParams } = new URL(url)
    return {
      type: "external" as const,
      url,
      hostname,
      search,
      searchParams: Object.fromEntries(searchParams),
    }
  }

  return undefined
}

export const getVideoEmbedUrl = (
  platform: VideoPlatform,
  video_id: string,
  timeCode: string | null
) => {
  if (platform === VideoPlatform.VIMEO) {
    if (timeCode) {
      return `https://player.vimeo.com/video/${video_id}#t=${timeCode}`
    }
    return `https://player.vimeo.com/video/${video_id}`
  } else if (platform === VideoPlatform.YOUTUBE) {
    if (timeCode) {
      return `https://www.youtube.com/embed/${video_id}?start=${timeCode}`
    } else {
      return `https://www.youtube.com/embed/${video_id}`
    }
  } else {
    throw new Error(`Unsupported platform: ${platform}`)
  }
}

/**
 * Ensures that the media source URL is valid and does not
 * contain an access key from the backend.
 */

export function validateMediaURL(src: string): string | undefined {
  if (process.env.NODE_ENV === "development") return src

  try {
    const srcUrl = new URL(src)
    const srcWithoutAccessKey = srcUrl.origin + srcUrl.pathname
    return srcWithoutAccessKey
  } catch (e) {
    sendErrorToSentry("could not construct url from media src", e)
    return undefined
  }
}

type DocumentResolution = {
  documentId: UUID
  revisionId?: UUID
  scrollToBlock?: UUID
}

export async function resolveDocumentUrl(
  urlToResolve: string
): Promise<false | DocumentResolution> {
  const url = new URL(urlToResolve)
  const revisionId = url.searchParams.get("r")
  const scrollToBlockId = url.hash.slice(1)

  try {
    const response = await fetch(`/api${url.pathname}`)
    const chapterResponse: ChapterResponse = await response.json()
    return {
      documentId: chapterResponse.id,
      revisionId: revisionId ? (revisionId as UUID) : undefined,
      scrollToBlock: scrollToBlockId ? (scrollToBlockId as UUID) : undefined,
    }
  } catch (e) {
    sendErrorToSentry(`Could not resolve document URL: ${url.pathname}`, e)
    return false
  }
}
