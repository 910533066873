import { FluentBundle, FluentResource } from "@fluent/bundle"
import { negotiateLanguages } from "@fluent/langneg"
import { LocalizationProvider, ReactLocalization } from "@fluent/react"
import { Children, type ReactNode, useEffect, useState } from "react"

const ftl: Record<string, URL> = {
  "en-US": new URL("/static/i18n/en-US.ftl", window.location.origin),
  de: new URL("/static/i18n/de.ftl", window.location.origin),
}

const DEFAULT_LOCALE = "en-US"
const AVAILABLE_LOCALES = {
  "en-US": "English",
  de: "German",
}

async function fetchMessages(locale: string): Promise<[string, string]> {
  const response = await fetch(String(ftl[locale]))
  const messages = await response.text()
  return [locale, messages]
}

function* lazilyParsedBundles(fetchedMessages: Array<[string, string]>) {
  for (const [locale, messages] of fetchedMessages) {
    const resource = new FluentResource(messages)
    const bundle = new FluentBundle(locale)
    bundle.addResource(resource)
    yield bundle
  }
}

interface AppLocalizationProviderProps {
  children: ReactNode
}

export function AppLocalizationProvider(props: AppLocalizationProviderProps) {
  const [l10n, setL10n] = useState<ReactLocalization | null>(null)

  useEffect(() => {
    changeLocales(navigator.languages as Array<string>)
  }, [])

  async function changeLocales(userLocales: Array<string>) {
    const currentLocales = negotiateLanguages(
      userLocales,
      Object.keys(AVAILABLE_LOCALES),
      { defaultLocale: DEFAULT_LOCALE }
    )

    const fetchedMessages = await Promise.all(currentLocales.map(fetchMessages))

    const bundles = lazilyParsedBundles(fetchedMessages)
    setL10n(new ReactLocalization(bundles))
  }

  if (l10n === null) {
    return <div>Loading…</div>
  }

  return (
    <LocalizationProvider l10n={l10n}>
      {Children.only(props.children)}
    </LocalizationProvider>
  )
}
