import { Transaction } from "prosemirror-state"

function getModifiedRangeFromTransaction(tr: Transaction) {
  let start = tr.doc.nodeSize
  let end = 0

  // https://discuss.prosemirror.net/t/how-to-calculate-the-changed-ranges-for-transactions/3771/4
  tr.mapping.maps.forEach((stepMap, index) => {
    stepMap.forEach((from, to) => {
      const newStart = tr.mapping.slice(index).map(from, -1)
      const newEnd = tr.mapping.slice(index).map(to)
      if (newStart < start) start = newStart
      if (newEnd > end) end = newEnd
    })
  })

  return { start, end }
}

export function getModifiedStartAndEnd(trs: readonly Transaction[]) {
  if (!trs.some((tr) => tr.docChanged))
    return { start: undefined, end: undefined }

  let finalStart = trs[trs.length - 1]?.doc.nodeSize ?? 0
  let finalEnd = 0

  trs.forEach((tr) => {
    const { start, end } = getModifiedRangeFromTransaction(tr)
    if (start < finalStart) finalStart = start
    if (end > finalEnd) finalEnd = end
  })

  return { start: finalStart, end: finalEnd }
}
