import type { Decoration } from "prosemirror-view"
import { type HTMLAttributes, forwardRef } from "react"

import styles from "./citationDroptarget.module.css"

import { Plus } from "@/components/icons"

export const CitationDropTarget = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement> & {
    widget: Decoration
    pos: number
  }
>(function CitationDropTarget({ widget: _widget, pos: _pos, ...props }, ref) {
  return (
    <div ref={ref} {...props} className={styles["container"]}>
      <hr className={styles["rule"]} />
      <div className={styles["flexWrapper"]}>
        <span className={styles["plus"]}>
          <Plus />
        </span>
      </div>
    </div>
  )
})
