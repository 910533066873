import type { NodeSchema } from "@/schemas/base-schema"

export function footnoteSchema(): NodeSchema<"footnote"> {
  return {
    footnote: {
      group: "inline",
      content: "(text|import_warning)*",
      inline: true,
      // This makes the view treat the node as a leaf, even though it
      // technically has content
      atom: true,
      attrs: { guid: { default: "" } },
      parseDOM: [{ tag: "footnote" }],
      toDOM(node) {
        return ["footnote", { "data-guid": node.attrs.guid }, 0]
      },
    },
  }
}
