import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { RejectedAction } from "@/types/utils"

export enum Dialog {
  NONE = "NONE",
  ERROR_CONNECTION_LOST = "ERROR_CONNECTION_LOST",
  ERROR_EXPIRED_SESSION = "ERROR_EXPIRED_SESSION",
  ERROR_CONFLICTING_CHANGES = "ERROR_CONFLICTING_CHANGES",
  INSERT_IMAGE = "INSERT_IMAGE",
  INSERT_LINK = "INSERT_LINK",
  INSERT_PDF = "INSERT_PDF",
  INSERT_VIDEO = "INSERT_VIDEO",
  INSERT_MAGIC_QUIZ = "INSERT_MAGIC_QUIZ",
  SETTINGS_CHAPTER = "SETTINGS_CHAPTER",
}

type DialogsState = {
  openDialog: Dialog
}

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState: { openDialog: Dialog.NONE } as DialogsState,
  reducers: {
    dialogClosed(state) {
      state.openDialog = Dialog.NONE
    },
    dialogOpened(state, action: PayloadAction<Dialog>) {
      state.openDialog = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected"),
      (state, action) => {
        if ((action.payload as { status?: number })?.status === 401)
          state.openDialog = Dialog.ERROR_EXPIRED_SESSION
      }
    )
  },
})
