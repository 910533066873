import mentionStyles from "./Mention.module.css"

import type { MarkSchema, NodeSchema } from "@/schemas/base-schema"

export function mentionSchema(): NodeSchema<"mention"> {
  return {
    mention: {
      atom: true,
      inline: true,
      group: "inline",
      attrs: {
        entityType: { default: "user" },
        entityId: { default: null },
        label: { default: "" },
      },
      selectable: false,
      draggable: false,

      toDOM: (node) => {
        return [
          "span",
          {
            class: mentionStyles["mention"],
            "data-mentioned-entity-id": node.attrs.entityId,
            "data-mentioned-entity-type": node.attrs.entityType,
          },
          node.attrs.label,
        ]
      },

      parseDOM: [
        {
          tag: "span[data-mentioned-entity-id][data-mentioned-entity-type]",
          getAttrs: (element) => {
            if (!(element instanceof HTMLElement)) return false
            return {
              entityId:
                element.getAttribute("data-mentioned-entity-id") ?? null,
              entityType:
                element.getAttribute("data-mentioned-entity-type") ?? "user",
              label: element.textContent ?? null,
            }
          },
        },
      ],
    },
  }
}

/**
 * This schema is deprecated since the new Mention node is implemented
 * as an atom node. This schema is kept for backward compatibility.
 */

export function deprecatedMentionSchema(): MarkSchema<"mention"> {
  return {
    mention: {
      attrs: {
        mentionType: { default: "user" },
        targetId: { default: null },
        targetName: { default: null },
      },
      inclusive: false,
      toDOM(node) {
        const { mentionType, targetId, targetName } = node.attrs
        return [
          "span",
          {
            class: "mention",
            "data-mention-type": mentionType,
            "data-target-id": targetId,
            "data-target-name": targetName,
          },
          0,
        ]
      },
    },
  }
}
