import { CommonBlockAttrs, type NodeSchema } from "@/schemas/base-schema"

export function foldableSchema(): NodeSchema<
  "foldable" | "foldable_summary" | "foldable_content"
> {
  return {
    foldable: {
      content: "foldable_summary foldable_content",
      group: "block",
      defining: true,
      isolating: true,
      allowGapCursor: false,
      atom: true,
      attrs: { ...CommonBlockAttrs.spec, open: { default: true } },
      parseDOM: [{ tag: "details", getAttrs: CommonBlockAttrs.parse }],
      toDOM(node) {
        return [
          "details",
          {
            ...CommonBlockAttrs.serialize(node),
            open: node.attrs.open,
            "data-type": "foldable",
          },
          0,
        ]
      },
    },

    foldable_summary: {
      content: "inline*",
      defining: true,
      selectable: false,
      isolating: true,
      parseDOM: [{ tag: "summary" }],
      toDOM() {
        return ["summary", 0]
      },
    },

    foldable_content: {
      content: "(paragraph | heading | bullet_list | ordered_list | foldable)+",
      defining: true,
      selectable: false,
      parseDOM: [{ tag: "div[data-type='foldableContent']" }],
      toDOM(_node) {
        return ["div", { "data-type": "foldableContent" }, 0]
      },
    },
  }
}
